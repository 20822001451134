.#{$component-name} {
  &.re-naver-main {
    font-family: 'NanumHuman', sans-serif;

    .header {
      &.transparent {
        &.#{$scrollUpClass} {
          background-color: rgba(29, 29, 31, .8) !important;
        }
      }
    }

    .br_pc {
      display: none;
    }

    .container {
      padding: 0;
      background-color: #111;
    }

    .btn-arrow {
      opacity: 0;
      transition: all 1s ease-in-out;
    }

    .fade-animation {
      position: relative;

      &__wrap {
        overflow: hidden;
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
      }

      &__box {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        width: 100%;
        transform: translate(-50%, -50%);
      }

      &__highlight-area {
        position: relative;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
      }

      &__highlight-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        transform: translateY(-50px);
        transition: all .6s cubic-bezier(0.37, 0, 0.63, 1);
        text-align: center;

        .text {
          opacity: 0.2;
          transition: opacity .3s ease-in-out;

          &.before-text {
            opacity: 1;
          }

          &.after-text {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            opacity: 0;
            transform: translateY(110px);
            transition: all .6s cubic-bezier(0.37, 0, 0.63, 1);
          }
        }

        .extend-wrap {
          overflow: hidden;
          display: block;
          position: relative;
          height: 100%;
          transition: all .6s cubic-bezier(0.37, 0, 0.63, 1);
        }

        &.is-show {
          opacity: 1;
          transform: translateY(-50%);
          transition: all 0.8s cubic-bezier(0.37, 0, 0.63, 1);

          .text {
            opacity: 1;
          }
        }

        &.is-extended {
          .text {
            &.before-text {
              opacity: 0;
              transform: translateY(-110px);
              transition: all 0.8s cubic-bezier(0.37, 0, 0.63, 1);
            }

            &.after-text {
              opacity: 1;
              transform: translateY(-50%);
              transition: all 0.8s cubic-bezier(0.37, 0, 0.63, 1);
            }
          }
        }
      }

      .btn-arrow {
        position: absolute;
        left: 50%;
        bottom: 70px;
        z-index: 10;
        width: 50px;
        height: 57px;
        margin-left: -25px;
        background: url('/img/common/ic-arrow-down.png') no-repeat;
        background-size: 100%;
        animation: arrowAnimation 1.6s cubic-bezier(.455, .03, .515, .955) infinite both;

        &.is-show {
          opacity: 1;
        }
      }
    }

    .js-scroll-nav-section {
      background-color: #1D1D1D;
    }

    .scroll-title {
      min-height: 100%;

      &__bar {
        .progress {
          background-color: #00E56D;
        }
      }
    }

    .main-visual {
      .video-pc, .video-mo {
        filter: brightness(1.1) contrast(1.2) saturate(1.3);
      }

      .video-mo {
        display: none;
      }

      .fade-animation {
        &__highlight-area {
          width: 100%;
        }

        &__highlight-text {
          text-align: center;

          .text {
            display: block;
            font-weight: 900;
            font-size: 38px;
            line-height: 1;
            letter-spacing: -0.76px;
            text-align: center;
            color: $color-white;
            filter: drop-shadow(0px 0px 75px #0A1E22);

            &.text-highlight {
              margin: 38px 0;
              font-size: 92px;
            }
          }

          .text-img {
            display: inline-block;
            width: 315px;
            height: 36px;
            background: url('/img/pc/naver-main-text.png') no-repeat center;
            background-size: cover;
            filter: drop-shadow(0px 0px 75px #0A1E22);
          }

          @at-root .navercorp.re-naver-main.lang-en {
            .main-visual .fade-animation__highlight-text .text {
              font-size: 92px;
              line-height: 1.2;
              letter-spacing: -1.84px;

              &.text-highlight {
                margin: 0;
                margin-bottom: 38px;
              }
            }
          }
        }
      }
    }

    .lottie {
      &__wrap {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
      }

      &__container {
        height: 100%;

        &#introLottie {
          padding: 110px;
        }

        &#outroLottie {
          svg {
            position: absolute;
            top: 70%;
            left: 50%;
            width: 145% !important;
            height: 145% !important;
            transform: translateX(-50%) !important;
          }
        }

        &#againLottie1 {
          display: none;

          &.is-active {
            display: block;
          }
        }

        &#againLottie2 {
          display: none;

          &.is-active {
            display: block;
          }
        }
      }
    }

    .common-copy {
      position: relative;

      .section__inner {
        margin: 140px 0;
      }

      .copy {
        &__title {
          font-weight: 700;
          font-size: 84px;
          line-height: 1.2;
          text-align: center;
          letter-spacing: -2.4px;
          color: $color-white;

          .text-highlight {
            color: #00E56D;
          }
        }
      }

      .btn-arrow {
        bottom: 27px;
        width: 44px;
        height: 50px;

        &__box {
          top: auto;
          bottom: 166px;
        }
      }
    }

    .common-card {
      .section__inner {
        max-width: 100%;
        margin: 0 0 0 80px;
        padding-bottom: 252px;
      }

      .card {
        overflow: hidden;

        &__list {
          margin: 0;
        }

        &__item {
          margin: 0;

          .thumbnail {
            padding-top: 98.04%;
            background: no-repeat center;
            background-size: cover;
            transition: transform .3s;
          }
        }

        &__txt {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 260px;
          padding: 24px 24px 26px;

          .tit {
            margin-top: 8px;
            font-weight: 700;
            font-size: 32px;
            line-height: 1.4;
            letter-spacing: -0.6px;
            color: $color-black;
          }

          .desc {
            margin-top: 50px;
            max-width: 355px;
            font-weight: 400;
            font-size: 20px;
            line-height: 1.4;
            letter-spacing: -0.4px;
            color: $color-black;
          }

          .date {
            font-weight: 700;
            font-size: 16px;
            line-height: 1;
            color: $color-black;
          }
        }
      }

      .swiper-navigation {
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: 10;
        width: 118px;
        transform: translateX(-50%);
      }

      .swiper-button-prev, .swiper-button-next {
        top: auto;
        bottom: 0;
        width: 52px;
        height: 52px;
        background: var(--ic-pagination-gray) no-repeat center;
        background-size: 100% auto;

        &:hover, &.is-active {
          background-image: url(/img/common/ic-pagination-arrow-gray-hover.png);
        }

        &::after {
          content: none;
        }
      }

      .swiper-button-prev {
        left: 0;
        right: auto;
      }

      .swiper-button-next {
        left: auto;
        right: 0;
        transform: rotate(180deg);

        &:hover, &.is-active {
          transform: rotate(180deg);
          background-image: url(/img/common/ic-pagination-arrow-gray-hover.png);
        }
      }
    }

    .intro {
      width: 100%;
      height: 100%;
      background: url(../img/pc/naver-main-intro-bg.jpg) no-repeat center;
      background-size: contain;

      .fade-animation {
        &__highlight-text {
          .text {
            display: block;
            font-weight: 400;
            font-size: 42px;
            line-height: 1;
            letter-spacing: -0.84px;
            color: $color-white;

            &:last-child {
              margin-top: 16px;
              font-weight: 700;
              font-size: 70px;
              line-height: 1.5;
              letter-spacing: -1.4px;
            }
          }
        }
      }
    }

    .beginning {
      &.is-active {
        .common-copy {
          transform: translateY(0);
          opacity: 1;
          transition: all 1s cubic-bezier(0.37, 0, 0.63, 1);
        }

        .common-card {
          transform: translateY(0);
          opacity: 1;
          transition: all 1s cubic-bezier(0.37, 0, 0.63, 1) 0.5s;
        }
      }

      .common-copy {
        transform: translateY(10%);
        opacity: 0;

        .section__inner {
          margin-bottom: 90px;
        }
      }

      .common-card {
        overflow: hidden;
        transform: translateY(10%);
        opacity: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .section__inner {
          max-width: 100%;
          margin: 0;
          padding-bottom: 140px;
        }

        .card {
          &.beginning-swiper {
            position: relative;
            padding: 0 90px 112px;
            transition: transform 0.5s ease;
          }

          &__item {
            width: 408px;

            &:first-child {
              .card__txt {
                background-color: #FEBA00;
              }

              .thumbnail {
                background-image: url(/img/pc/naver-main-begin1.jpg);
              }
            }

            &:nth-child(2) {
              .card__txt {
                background-color: #85F21F;
              }

              .thumbnail {
                background-image: url(/img/pc/naver-main-begin2.jpg);
              }
            }

            &:nth-child(3) {
              .card__txt {
                background-color: #00E56D;
              }

              .thumbnail {
                background-image: url(/img/pc/naver-main-begin3.jpg);
              }
            }

            &:nth-child(4) {
              .card__txt {
                background-color: #009A66;
              }

              .thumbnail {
                background-image: url(/img/pc/naver-main-begin4.jpg);
              }
            }

            &:nth-child(5) {
              .card__txt {
                background-color: #B1B1B1;
              }

              .thumbnail {
                background-image: url(/img/pc/naver-main-begin5.jpg);
              }
            }

            &:nth-child(6) {
              .card__txt {
                background-color: #8ABFFF;
              }

              .thumbnail {
                background-image: url(/img/pc/naver-main-begin6.jpg);
              }
            }

            &:nth-child(7) {
              .card__txt {
                background-color: #856EF1;
              }

              .thumbnail {
                background-image: url(/img/pc/naver-main-begin7.jpg);
              }
            }

            &:last-child {
              .card__txt {
                background-color: #9FACBE;
              }

              .thumbnail {
                background-image: url(/img/pc/naver-main-begin8.jpg);
              }
            }

            &:hover {
              .card__img .thumbnail {
                transform: scale(1.05);
              }
            }
          }

          &__txt {
            @at-root .navercorp.re-naver-main.lang-en {
              .beginning .common-card .card__txt {
                justify-content: flex-start;
                padding: 24px 21px 32px 24px;
              }
            }
          }

          &__img {
            overflow: hidden;
            position: relative;
          }
        }
      }
    }

    .again {
      scroll-behavior: smooth;

      #againTitle {
        opacity: 0;
        transform: translateY(10%);
        transition: all 1s cubic-bezier(0.37, 0, 0.63, 1);
      }

      &.is-active {
        #againTitle {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .sticky-section {
        position: relative;

        &__inner {
          overflow: hidden;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 2;
        }

        &__content {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 0 136px 0 80px;
          margin: 110px 0 112px;
          transition: all 0.3s ease;
          z-index: 10;

          &:first-child {
            .sticky-section__text .title {
              color: #00E56D;
            }

            .sticky-section__img {
              display: none;
            }
          }

          &:nth-child(2) {
            .sticky-section__text .title {
              color: #7346F3;
            }

            .sticky-section__lineup-ad-text .title {
              color: $color-white;
            }

            .sticky-section__img {
              display: none;
            }
          }

          &:last-child {
            margin-bottom: 105px;

            .sticky-section__text .title {
              color: #3B6AEB;
            }

            .sticky-section__img {
              display: none;
            }
          }
        }

        &__text {
          width: 655px;

          .title {
            font-size: 32px;
            font-weight: 700;
            line-height: 1;
            letter-spacing: -0.64px;
          }

          .sub-title {
            display: block;
            margin-top: 16px;
            font-size: 50px;
            font-weight: 700;
            line-height: 1.4;
            letter-spacing: -1px;
            color: $color-white;
          }
        }

        &__lineup {
          width: 588px;

          &-item {
            margin-top: 35px;
            opacity: 0.2;
            transition: all 0.3s ease;

            &:first-child {
              margin-top: 0;
            }

            &.highlight {
              opacity: 1;
            }
          }

          &-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 24px;
          }

          &-text {
            .date {
              display: block;
              font-size: 16px;
              font-weight: 700;
              line-height: 1.4;
              letter-spacing: -0.32px;
              color: #7E8687;
            }

            .title {
              display: block;
              margin-top: 2px;
              font-size: 27px;
              font-weight: 700;
              line-height: 1.4;
              letter-spacing: -0.54px;
              color: $color-white;
            }

            .desc {
              display: block;
              margin-top: 5px;
              font-size: 18px;
              font-weight: 400;
              line-height: 1.6;
              letter-spacing: -0.36px;
              color: #7E8687;
            }

            .title-mo {
              display: none;
            }
          }

          &-icon {
            margin-left: 24px;

            img {
              width: 76px;
              border-radius: 14px;
            }
          }

          &-ad {
            position: relative;
            margin-top: 50px;
            height: auto;
            opacity: 0.2;
            transition: all 0.3s ease;

            &.highlight {
              opacity: 1;
            }

            &-img {
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              border-radius: 18px;
            }

            &-box {
              display: flex;
              justify-content: flex-start;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              padding: 18px 24px 26px;
            }

            &-text {
              .date {
                display: block;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.4;
                letter-spacing: -0.32px;
                color: #7E8687;
              }

              .title {
                display: block;
                margin-top: 2px;
                font-size: 27px;
                font-weight: 700;
                line-height: 1.4;
                letter-spacing: -0.54px;
                color: $color-black;
              }

              .desc {
                display: block;
                margin-top: 5px;
                font-size: 18px;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: -0.36px;
                color: #7E8687;
              }

              .title-mo {
                display: none;
              }
            }
          }

        }

        @at-root .navercorp.re-naver-main.lang-en {
          .again .sticky-section__lineup-text {
            .date {
              font-size: 14px;
              letter-spacing: -0.28px;
            }

            .title {
              font-size: 24px;
              letter-spacing: -0.48px;
            }

            .desc {
              font-size: 16px;
              letter-spacing: -0.32px;
            }
          }

          .again .sticky-section__lineup-ad-text {
            .date {
              font-size: 14px;
              letter-spacing: -0.28px;
            }

            .title {
              font-size: 24px;
              letter-spacing: -0.48px;
            }

            .desc {
              font-size: 16px;
              letter-spacing: -0.32px;
            }
          }
        }

        .lottie {
          &__wrap {
            position: absolute;

            &.is-sticky {
              position: sticky;
            }
          }

          &__container {
            position: absolute;
            width: 100%;
            height: 100%;
            pointer-events: none;
          }
        }
      }
    }

    .finish {
      overflow: hidden;
      padding-bottom: 153px;

      &.is-active {
        .common-copy {
          transform: translateY(0);
          opacity: 1;
          transition: all 1s cubic-bezier(0.37, 0, 0.63, 1);
        }

        .common-card {
          .card__cont-text {
            transform: translateY(0);
            opacity: 1;
            transition: all 1s cubic-bezier(0.37, 0, 0.63, 1) 0.5s;
          }

          .card.finish-swiper {
            transform: translateX(0);
            opacity: 1;
            transition: all 0.7s cubic-bezier(0.37, 0, 0.63, 1) 0.5s;
          }
        }
      }

      .common-copy {
        opacity: 0;
        transform: translateY(10%);

        .section__inner {
          margin-top: 165px;
        }
      }

      .common-card {
        position: relative;
        margin-top: 240px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .section__inner {
          padding: 0;
          display: flex;
        }

        .swiper-navigation {
          position: absolute;
          bottom: 50px;
          left: 0;
          transform: none;
        }

        .card {
          padding: 0;

          &__cont {
            position: relative;
            margin-right: 210px;
            color: $color-white;

            &-text {
              width: 494px;
              transform: translateY(20%);
              opacity: 0;
            }

            .tit {
              font-weight: 700;
              font-size: 50px;
              line-height: 1.46;
              letter-spacing: -1px;
              color: $color-white;
            }

            .tit-mo {
              display: none;
            }

            .desc {
              margin-top: 14px;
              font-weight: 400;
              font-size: 24px;
              line-height: 1.6;
              letter-spacing: -0.48px;
              color: #7E8687;
            }
          }

          &__txt {
            padding: 0;
            height: auto;

            .tit {
              margin-top: 8px;
              font-weight: 700;
              font-size: 32px;
              line-height: 1.4;
              letter-spacing: -0.64px;
              color: $color-white;
            }

            .desc {
              margin-top: 0;
              font-weight: 400;
              font-size: 22px;
              line-height: 1.4;
              letter-spacing: -0.44px;
              color: $color-white;
              opacity: 0.8;
            }
          }

          &__list {
            width: fit-content;
          }

          &__item {
            display: inline-block;
            width: 420px;
            height: 566px;
            padding: 32px;
            box-sizing: border-box;

            &:last-child {
              margin-right: 80px;
            }
          }

          &.finish-swiper {
            position: relative;
            opacity: 0;
            transform: translateX(160px);
          }

          @at-root .navercorp.re-naver-main.lang-en {
            .finish .common-card .card__cont {
              margin-right: 194px;

              &-text {
                width: 510px;
              }
            }
          }
        }

        &.card01 {
          margin-top: 0;

          .card__item {
            &:first-child {
              background: url(/img/pc/naver-finish1-card1.jpg) no-repeat center;
              background-size: cover;
            }

            &:nth-child(2) {
              background: url(/img/pc/naver-finish1-card2.jpg) no-repeat center;
              background-size: cover;
            }

            &:nth-child(3) {
              background: url(/img/pc/naver-finish1-card3.jpg) no-repeat center;
              background-size: cover;
            }

            &:nth-child(4) {
              background: url(/img/pc/naver-finish1-card4.jpg) no-repeat center;
              background-size: cover;
            }

            &:last-child {
              background: url(/img/mo/naver-finish1-card5.jpg) no-repeat center;
              background-size: cover;
            }
          }
        }

        &.card02 {

          .card__item {
            &:first-child {
              background: url(/img/pc/naver-finish2-card1.jpg) no-repeat center;
              background-size: cover;
            }

            &:nth-child(2) {
              background: url(/img/pc/naver-finish2-card2.jpg) no-repeat center;
              background-size: cover;
            }

            &:nth-child(3) {
              background: url(/img/pc/naver-finish2-card3.jpg) no-repeat center;
              background-size: cover;
            }

            &:nth-child(4) {
              background: url(/img/pc/naver-finish2-card4.jpg) no-repeat center;
              background-size: cover;
            }

            &:last-child {
              background: url(/img/pc/naver-finish2-card5.jpg) no-repeat center;
              background-size: cover;
            }
          }
        }

        &.card03 {

          .card__item {
            &:first-child {
              background: url(/img/pc/naver-finish3-card1.jpg) no-repeat center;
              background-size: cover;
            }

            &:nth-child(2) {
              background: url(/img/pc/naver-finish3-card2.jpg) no-repeat center;
              background-size: cover;
            }

            &:nth-child(3) {
              background: url(/img/pc/naver-finish3-card3.jpg) no-repeat center;
              background-size: cover;
            }

            &:nth-child(4) {
              background: url(/img/pc/naver-finish3-card4.jpg) no-repeat center;
              background-size: cover;
            }

            &:nth-child(5) {
              background: url(/img/pc/naver-finish3-card5.jpg) no-repeat center;
              background-size: cover;
            }

            &:last-child {
              background: url(/img/pc/naver-finish3-card6.jpg) no-repeat center;
              background-size: cover;
            }
          }
        }
      }
    }

    .ending {
      .fade-animation {
        &__box {
          @at-root .navercorp.re-naver-main.lang-en {
            .ending .fade-animation__box {
              top: 35%;
            }
          }
        }

        &__highlight-text {
          flex-direction: column;
          justify-content: space-between;

          .text {
            display: block;
            font-weight: 700;
            font-size: 76px;
            line-height: 1.36;
            letter-spacing: -1.52px;
            color: #545859;
            flex: 1;

            &.before-text {
              color: #7E8687;
            }

            &.after-text {
              color: $color-white;

              .text-highlight {
                color: #00E56D;
              }
            }
          }

          .extend-wrap {
            flex: 2;
          }

          &.kor {
            display: flex;
            height: 309px;

            &.is-show {
              transform: translateY(-72%);
            }

            &.is-extended {
              height: 412px;
            }
          }

          &.eng {
            display: none;
            height: 206px;
          }

          @at-root .navercorp.re-naver-main.lang-en {
            .ending .fade-animation__highlight-text.kor {
              display: none;
            }

            .ending .fade-animation__highlight-text.eng {
              display: flex;
            }

            .ending .fade-animation__highlight-text.eng.is-extended {
              height: 309px;
            }
          }
        }
      }
    }

    .info {
      display: flex;
      background-color: #242424;

      &__img {
        overflow: hidden;
        width: 50%;
        font-size: 0;

        .img {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding-top: 100%;
          background: url(/img/pc/naver-info-img.jpg) no-repeat center center;
          background-size: cover;
          transform: scale(1.3);
          transition: all 0.8s;
        }

        &.#{$activeClass} {
          .img {
            transform: scale(1);
          }
        }
      }

      &__txt {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        padding: 60px;
        background-color: $color-default;

        .logo {
          display: inline-block;
          width: 156px;
          height: 30px;
          background: url(/img/common/logo-w.svg) no-repeat center center;
          background-size: 100%;
        }

        .txt {
          &__item {
            display: flex;
            align-items: center;
            font-size: 0;
            padding: 4px 0;
            border-top: 1px solid rgb(255, 255, 255, .2);

            &:first-child {
              border: none;
            }

            .tit, .desc {
              display: inline-block;
              font-weight: 400;
              font-size: 14px;
              line-height: 3;
              letter-spacing: -0.56px;
              color: $color-white;
            }

            .tit {
              width: 82px;
              color: #a1a1a6;

              @at-root .#{$component-name} {
                &.lang-en {
                  .info__txt .txt__item .tit {
                    width: 155px;
                  }
                }
              }
            }

            .desc {
              margin-left: 100px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    .relate-content {
      padding: 70px 0 130px;
      background: #111;
      color: $color-white;

      .card__sub.is-scroll-view {
        h4 {
          color: $color-white;
        }
      }

      .card__sub {
        .sub__txt {
          color: $color-white;

          .news-date {
            color: #6e6e73;
          }
        }
      }
    }
  }
}

// PC : 1600 ~ 1919
@include breakpoint(pc) {
  .#{$component-name} {
    &.re-naver-main {
      .br_pc {
        display: block;
      }

      .fade-animation {
        .btn-arrow {
          bottom: 60px;
          width: 42px;
          height: 47px;
          margin-left: -21px;
        }
      }

      .main-visual {
        .fade-animation {
          &__highlight-text {
            text-shadow: 0 0 63px rgba(10, 30, 34, 1);

            .text {
              font-size: 32px;
              letter-spacing: -0.64px;

              &.text-highlight {
                margin: 32px 0;
                font-size: 76px;
                line-height: 1;
              }
            }

            .text-img {
              width: 262px;
              height: 30px;
            }

            @at-root .navercorp.re-naver-main.lang-en {
              .main-visual .fade-animation__highlight-text .text {
                font-size: 76px;
                letter-spacing: -1.52px;

                &.text-highlight {
                  margin: 0;
                  margin-bottom: 32px;
                }
              }
            }
          }
        }
      }

      .lottie {
        &__container {

          &#introLottie {
            padding: 90px;
          }

          &#outroLottie {
            svg {
              width: 180% !important;
              height: 180% !important;
            }
          }
        }
      }

      .common-copy {
        .section__inner {
          margin: 120px 0;
        }

        .copy {
          &__title {
            font-size: 70px;
            line-height: 1.2;
            letter-spacing: -1.4px;
          }
        }
      }

      .common-card {
        .section__inner {
          padding-top: 150px;
          padding-bottom: 100px;
        }

        .swiper-navigation {
          width: 100px;
        }

        .swiper-button-prev, .swiper-button-next {
          width: 44px;
          height: 44px;
        }
      }

      .intro {
        .fade-animation {
          &__highlight-text {
            .text {
              font-size: 35px;
              letter-spacing: -0.7px;

              &:last-child {
                margin-top: 14px;
                font-size: 58px;
                letter-spacing: -1.74px;
              }
            }
          }
        }
      }

      .beginning {
        .common-copy {
          .section__inner {
            margin-bottom: 80px;
          }
        }

        .common-card {
          .section__inner {
            padding-top: 0;
            padding-bottom: 120px;
          }

          .card {
            &.beginning-swiper {
              padding: 0 70px 94px;
            }

            &__item {
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      .again {
        .sticky-section {
          &__content {
            padding: 0 140px 0 80px;
          }

          &__text {
            width: 620px;

            .title {
              font-size: 28px;
              letter-spacing: -0.28px;
            }

            .sub-title {
              margin-top: 12px;
              font-size: 42px;
              line-height: 1.4;
              letter-spacing: -1.26px;
            }
          }

          &__lineup {
            width: 476px;

            &-box {
              padding-left: 20px;
            }

            &-text {
              .date {
                font-size: 14px;
                letter-spacing: -0.28px;
              }

              .title {
                margin-top: 0;
                font-size: 22px;
                letter-spacing: -0.44px;
              }

              .desc {
                margin-top: 3px;
                font-size: 15px;
                letter-spacing: -0.3px;
              }
            }

            &-icon {
              margin-left: 20px;

              img {
                width: 64px;
              }
            }

            &-ad {
              margin-top: 40px;

              &-img {
                border-radius: 15px;
              }

              &-box {
                padding: 16px 20px 20px;
              }

              &-text {
                .date {
                  font-size: 14px;
                  letter-spacing: -0.28px;
                }

                .title {
                  margin-top: 0;
                  font-size: 22px;
                  letter-spacing: -0.44px;
                }

                .desc {
                  margin-top: 3px;
                  font-size: 15px;
                  letter-spacing: -0.3px;
                }
              }
            }

          }

          @at-root .navercorp.re-naver-main.lang-en {
            .again .sticky-section__text {
              .sub-title {
                font-size: 40px;
                letter-spacing: -0.8px;
              }
            }

            .again .sticky-section__lineup-text {
              .title {
                font-size: 18px;
                letter-spacing: -0.36px;
              }

              .desc {
                font-size: 14px;
                letter-spacing: -0.28px;
              }
            }

            .again .sticky-section__lineup-ad-text {
              .title {
                font-size: 18px;
                letter-spacing: -0.36px;
              }

              .desc {
                font-size: 14px;
                letter-spacing: -0.28px;
              }
            }
          }
        }
      }

      .finish {
        overflow: hidden;
        padding-bottom: 120px;

        .common-copy {
          .section__inner {
            margin-top: 135px;
          }
        }

        .common-card {
          margin-top: 200px;

          .swiper-navigation {
            bottom: 40px;
          }

          .card {
            &__cont {
              margin-right: 188px;

              &-text {
                width: 417px;
              }

              .tit {
                font-size: 42px;
                letter-spacing: -0.84px;
              }

              .desc {
                margin-top: 12px;
                font-size: 18px;
                letter-spacing: -0.36px;
              }
            }

            &__txt {
              min-height: auto;

              .tit {
                margin-top: 4px;
                font-size: 28px;
                letter-spacing: -0.56px;
              }
            }

            &__item {
              width: 350px;
              height: 472px;
              padding: 30px;
            }
          }

          @at-root .navercorp.re-naver-main.lang-en {
            .finish .common-card .card__txt {
              .tit {
                font-size: 26px;
                letter-spacing: -0.52px;
              }

              .desc {
                font-size: 20px;
                letter-spacing: -0.4px;
              }
            }

            .finish .common-card .card__cont {
              margin-right: 180px;

              &-text {
                width: 380px;
              }
            }
          }
        }
      }

      .ending {
        .fade-animation {
          &__highlight-text {
            .text {
              font-size: 70px;
              letter-spacing: -1.4px;
            }

            &.kor {
              height: 285px;

              &.is-extended {
                height: 380px;
              }
            }

            &.eng {
              height: 190px;
            }

            @at-root .navercorp.re-naver-main.lang-en {
              .ending .fade-animation__highlight-text.eng.is-extended {
                height: 285px;
              }
            }
          }
        }
      }

      .info {
        &__txt {
          padding: 40px;

          .logo {
            width: 130px;
            height: 25px;
          }

          .txt__item .desc {
            width: 520px;
            margin-left: 50px;
          }

          .txt__item .tit {
            line-height: 1.71;
          }
        }
      }

      .relate-content {
        padding: 63px 0 128px;
      }
    }
  }
}

// Tablet : 1024 ~ 1599
@include breakpoint(tablet) {
  .#{$component-name} {
    &.re-naver-main {
      .br_pc {
        display: none;
      }

      .section__inner {
        max-width: none;
        margin: 120px 0;
      }

      .main-visual {
        .fade-animation {
          &__highlight-text {
            text-shadow: 0 0 40px rgba(10, 30, 34, 1);

            .text {
              font-size: 32px;
              letter-spacing: -0.64px;

              &.text-highlight {
                margin: 28px 0;
                font-size: 68px;
                letter-spacing: -1.36px;
              }
            }

            .text-img {
              width: 227px;
              height: 26px;
            }

            @at-root .navercorp.re-naver-main.lang-en {
              .main-visual .fade-animation__highlight-text .text {
                font-size: 68px;
                letter-spacing: -1.36px;

                &.text-highlight {
                  margin: 0;
                  margin-bottom: 28px;
                }
              }
            }
          }
        }
      }

      .lottie {
        &__container {
          &#introLottie {
            padding: 0 152px;
          }

          &#outroLottie {
            svg {
              top: 70%;
              width: 145% !important;
              height: 145% !important;
            }
          }
        }
      }

      .common-copy {
        padding: 0 30px;

        .section__inner {
          margin: 120px 0 90px;
        }

        .copy {
          &__title {
            font-size: 60px;
            line-height: 1.2;
            letter-spacing: -1.2px;
          }
        }
      }

      .common-card {
        .card {
          &__img {
            .thumbnail {
              padding-top: 100%;
            }
          }

          &__txt {
            justify-content: space-between;
            height: 209px;
            padding: 20px 20px 33px;

            .date {
              font-size: 12px;
              letter-spacing: -0.24px;
            }

            .tit {
              margin-top: 4px;
              font-size: 24px;
              letter-spacing: -0.48px;
            }

            .desc {
              max-width: none;
              margin-top: 40px;
              font-size: 16px;
              letter-spacing: -0.32px;
            }
          }
        }

        .swiper-navigation {
          width: 84px;
        }

        .swiper-button-prev, .swiper-button-next {
          width: 36px;
          height: 36px;
        }
      }

      .intro {
        background: url(../img/mo/naver-main-intro-bg-tb.jpg) no-repeat center;

        .fade-animation {
          &__highlight-text {
            .text {
              font-size: 35px;
              letter-spacing: -0.7px;

              &:last-child {
                margin-top: 14px;
                font-size: 54px;
                letter-spacing: -1.08px;
              }
            }
          }
        }
      }

      .beginning {
        .common-card {
          .section__inner {
            padding-bottom: 120px;
          }

          .card {
            &.beginning-swiper {
              padding: 0 100px 74px;
            }

            &__item {
              width: 325px;
            }

            &__txt {
              @at-root .navercorp.re-naver-main.lang-en {
                .beginning .common-card .card__txt {
                  padding: 20px 20px 33px;
                }
              }
            }
          }
        }
      }

      .again {
        .lottie {
          &__wrap {
            display: none;
          }
        }

        .common-copy {
          .section__inner {
            margin-bottom: 120px;
          }
        }

        .sticky-section {
          &__content {
            flex-direction: column;
            align-items: flex-end;
            margin-top: 146px;
            padding: 0 60px;

            &:first-child {
              margin-top: 0;
            }

            &:first-child, &:nth-child(2) {
              .sticky-section__img {
                display: inline-block;
                width: calc(100% - 590px);
                height: 702px;
                object-fit: contain;
              }
            }

            &:last-child {
              .sticky-section__img {
                display: inline-block;
                width: calc(100% - 590px);
                height: 948px;
                object-fit: contain;
              }
            }
          }

          &__text {
            width: 100%;

            .title {
              font-size: 26px;
              letter-spacing: -0.52px;
            }

            .sub-title {
              margin-top: 8px;
              font-size: 36px;
              letter-spacing: -0.72px;
            }
          }

          &__img {
            margin-top: 80px;
          }

          &__lineup {
            width: 462px;
            margin-top: 80px;

            &-wrap {
              display: flex;
              justify-content: space-between;
              width: 100%;
            }

            &-text {
              .date {
                font-size: 14px;
                letter-spacing: -0.28px;
              }

              .title {
                margin-top: 0;
                font-size: 22px;
                letter-spacing: -0.44px;
              }

              .desc {
                margin-top: 2px;
                font-size: 15px;
                letter-spacing: -0.3px;
              }
            }

            &-ad {
              margin-top: 40px;
              border-radius: 15px;

              &-box {
                padding: 16px 20px 20px;
              }

              &-text {
                .date {
                  font-size: 14px;
                  letter-spacing: -0.28px;
                }

                .title {
                  font-size: 22px;
                  letter-spacing: -0.44px;
                }

                .desc {
                  margin-top: 2px;
                  font-size: 15px;
                  letter-spacing: -0.3px;
                }
              }
            }
          }

          @at-root .navercorp.re-naver-main.lang-en {
            .again .sticky-section__text {
              .sub-title {
                font-size: 36px;
                letter-spacing: -0.72px;
              }
            }
          }
        }
      }

      .finish {
        padding-bottom: 120px;

        .common-copy {
          .section__inner {
            margin-bottom: 80px;
          }
        }

        .common-card {
          margin-top: 140px;

          .section__inner {
            flex-direction: column;
            margin: 0;
          }

          .swiper-navigation {
            bottom: 0;
            left: auto;
            right: 60px;
          }

          .card {
            margin-top: 40px;
            padding-left: 60px;

            &__cont {
              margin-right: 0;
              padding-left: 60px;

              &-text {
                width: 100%;
                padding-right: 160px;
              }

              .tit {
                font-size: 36px;
                letter-spacing: -0.72px;
              }

              .desc {
                margin-top: 8px;
                font-size: 18px;
                letter-spacing: -0.36px;
              }
            }

            &__txt {
              .tit {
                margin-top: 4px;
                font-size: 28px;
                letter-spacing: -0.56px;
              }
            }

            &__item {
              width: 350px;
              height: 472px;
              padding: 30px;

              &:last-child {
                margin-right: 60px;
              }
            }
          }

          @at-root .navercorp.re-naver-main.lang-en {
            .finish .common-card .card__cont {
              margin-right: 0;

              &-text {
                width: 100%;
              }
            }
          }
        }
      }

      .ending {
        .fade-animation {
          &__box {
            top: 50%;
          }

          &__highlight-text {
            .text {
              font-size: 58px;
              letter-spacing: -1.16px;
            }

            &.kor {
              height: 243px;

              &.is-extended {
                height: 324px;
              }
            }

            &.eng {
              height: 180px;
            }

            @at-root .navercorp.re-naver-main.lang-en {
              .ending .fade-animation__highlight-text.eng.is-extended {
                height: 270px;
              }

              .ending .fade-animation__highlight-text .text {
                font-size: 64px;
                letter-spacing: -1.28px;
              }
            }
          }
        }
      }

      .info {
        flex-direction: column;

        &__img {
          width: 100%;

          .img {
            padding-top: 66.7%;
            background-position-y: 30%;
          }
        }

        &__txt {
          width: 100%;

          .txt__list {
            margin-top: 210px;
          }
        }
      }

      .relate-content {
        padding: 60px 0 80px;
      }
    }
  }
}

// Tablet-sm : 768 ~ 1023
@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.re-naver-main {
      .section__inner {
        margin: 80px 0;
      }

      .fade-animation {
        .btn-arrow {
          bottom: 50px;
          width: 34px;
          height: 38px;
          margin-left: -17px;
        }
      }

      .main-visual {
        .fade-animation {
          &__highlight-text {
            text-shadow: 0 0 30px rgba(10, 30, 34, 1);

            .text {
              font-size: 24px;
              letter-spacing: -0.48px;

              &.text-highlight {
                margin: 14px 0;
                font-size: 56px;
                line-height: 1.3;
                letter-spacing: -1.12px;
              }
            }

            .text-img {
              width: 192px;
              height: 22px;
            }

            @at-root .navercorp.re-naver-main.lang-en {
              .main-visual .fade-animation__highlight-text .text {
                font-size: 56px;
                letter-spacing: -1.12px;

                &.text-highlight {
                  margin: 0;
                  margin-bottom: 14px;
                }
              }
            }
          }
        }
      }

      .lottie {
        &__container {
          &#introLottie {
            padding: 0 114px;
          }

          &#outroLottie {
            svg {
              width: 120% !important;
              height: 120% !important;
            }
          }
        }
      }

      .common-copy {
        .section {
          &__inner {
            margin: 120px 0 100px;
          }
        }

        .copy {
          &__title {
            font-size: 48px;
            line-height: 1.3;
            letter-spacing: -0.96px;
          }
        }

        .btn-arrow {
          width: 36px;
          height: 41px;

          &__box {
            bottom: 100px;
          }
        }
      }

      .common-card {
        .card {
          &__txt {
            justify-content: flex-start;
            height: 175px;
            padding-bottom: 20px;

            .tit {
              margin-top: 2px;
              font-size: 22px;
              line-height: 1.4;
              letter-spacing: -0.44px;
            }

            .desc {
              margin-top: 30px;
              font-size: 14px;
              line-height: 1.4;
              letter-spacing: -0.28px;
            }
          }

          @at-root .navercorp.re-naver-main.lang-en {
            .common-card .card__txt .desc {
              font-size: 13px;
              letter-spacing: -0.26px;
            }
          }
        }
      }

      .intro {
        .fade-animation {
          &__highlight-text {
            .text {
              font-size: 22px;
              letter-spacing: -0.88px;

              &:last-child {
                margin-top: 12px;
                font-size: 46px;
                letter-spacing: -0.92px;
              }
            }
          }
        }
      }

      .beginning {
        .common-copy {
          .section__inner {
            margin-bottom: 60px;
          }
        }

        .common-card {
          .card {
            &.beginning-swiper {
              padding: 0 30px 74px;
            }

            &__item {
              width: 275px;
            }

            &__txt {
              padding: 20px 12px 22px 20px;
            }

            @at-root .navercorp.re-naver-main.lang-en {
              .beginning .common-card .card__txt {
                padding: 20px 20px 28px;
              }
            }
          }
        }
      }

      .again {
        .common-copy {
          padding: 0 60px;

          .section__inner {
            margin-bottom: 100px;
          }
        }

        .sticky-section {
          height: 100% !important;

          &__inner {
            position: relative;
            height: 100%;
          }

          &__content {
            margin-top: 100px;
            margin-bottom: 0;

            &:first-child {
              margin-top: 0;
            }

            &:first-child, &:nth-child(2), &:last-child {
              .sticky-section__img {
                display: none;
              }
            }
          }

          &__text {
            padding: 0 20px;

            .title {
              font-size: 22px;
              line-height: 1.4;
              letter-spacing: -0.44px;
            }

            .sub-title {
              font-size: 32px;
              letter-spacing: -0.64px;
            }
          }

          &__lineup {
            width: 100%;

            &-box {
              padding: 0 20px;
            }

            &-ad {
              margin-top: 50px;
              height: 133px;
              border-radius: 12px;

              &-box {
                padding: 16px 20px 24px;
              }

              &-text {
                .date {
                  line-height: 1.13;
                }

                .title {
                  margin-top: 2px;
                }

                .desc {
                  line-height: 1.4;
                }
              }
            }

            &-wrap {
              display: block;
            }
          }

          @at-root .navercorp.re-naver-main.lang-en {
            .again .sticky-section__text {
              .sub-title {
                font-size: 30px;
                letter-spacing: -0.6px;
              }
            }

            .again .sticky-section__lineup-text {
              .title {
                font-size: 22px;
                letter-spacing: -0.44px;
              }

              .desc {
                font-size: 15px;
                letter-spacing: -0.3px;
              }
            }

            .again .sticky-section__lineup-ad-text {
              .title {
                font-size: 22px;
                letter-spacing: -0.44px;
              }

              .desc {
                font-size: 15px;
                letter-spacing: -0.3px;
              }
            }
          }
        }
      }

      .finish {
        .common-copy {
          .section__inner {
            margin-bottom: 100px;
          }
        }

        .common-card {
          margin-top: 100px;

          .section__inner {
            margin: 0;
          }

          .card {
            &__cont {
              padding-left: 60px;

              .tit {
                font-size: 32px;
                line-height: 1.4;
                letter-spacing: -0.64px;
              }

              .desc {
                font-size: 16px;
                letter-spacing: -0.32px;
              }
            }

            &__txt {
              .tit {
                margin-top: 0;
                font-size: 22px;
                letter-spacing: -0.44px;
              }

              .desc {
                font-size: 18px;
                letter-spacing: -0.36px;
              }
            }

            &__item {
              width: 280px;
              height: 385px;
              padding: 20px;
            }
          }

          @at-root .navercorp.re-naver-main.lang-en {
            .finish .common-card .card__txt {
              .tit {
                margin-top: 2px;
                font-size: 22px;
                letter-spacing: -0.44px;
              }

              .desc {
                font-size: 18px;
                letter-spacing: -0.36px;
              }
            }
          }
        }
      }

      .ending {
        .fade-animation {
          &__box {
            top: 50%;
          }

          &__highlight-text {
            .text {
              font-size: 52px;
              line-height: 1.3;
              letter-spacing: -1.04px;
            }

            &.kor {
              height: 204px;

              &.is-show {
                transform: translateY(-77%);
              }

              &.is-extended {
                height: 272px;
              }
            }

            &.eng {
              height: 124px;
            }

            @at-root .navercorp.re-naver-main.lang-en {
              .ending .fade-animation__highlight-text.eng.is-extended {
                height: 186px;
              }

              .ending .fade-animation__highlight-text .text {
                font-size: 48px;
                letter-spacing: -0.96px;
              }
            }
          }
        }
      }

      .info {
        &__txt {
          padding: 40px 30px 50px;

          .txt__list {
            margin-top: 120px;
          }

          .txt__item {
            padding: 14px 0;

            .tit,
            .desc {
              line-height: 1.57;
            }

            .desc {
              margin-left: 31px;
            }
          }
        }
      }
    }
  }
}

// Mobile : 375 ~ 767
@include breakpoint(mobile) {
  .#{$component-name} {
    &.re-naver-main {
      .fade-animation {
        .btn-arrow {
          bottom: 40px;
          width: 30px;
          height: 34px;
          margin-left: -15px;
        }
      }

      .main-visual {
        .video-pc {
          display: none;
        }

        .video-mo {
          display: block;
        }

        .fade-animation {
          &__highlight-area {
            width: auto;
            max-width: 375px;
          }

          &__highlight-text {
            text-shadow: 0 0 10px rgba(10, 30, 34, 1);

            .text {
              font-size: 20px;
              letter-spacing: -0.4px;

              &.text-highlight {
                font-size: 40px;
                letter-spacing: -0.8px;
              }
            }

            .text-img {
              width: 166px;
              height: 19px;
            }

            @at-root .navercorp.re-naver-main.lang-en {
              .main-visual .fade-animation__highlight-text .text {
                font-size: 40px;
                letter-spacing: -0.8px;

                &.text-highlight {
                  margin: 0;
                  margin-bottom: 14px;
                }
              }
            }
          }
        }
      }

      .lottie {
        &__container {
          &#introLottie {
            padding: 0;

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 144% !important;
              height: 144% !important;
              transform: translate(-50%, -50%) !important;
            }
          }

          &#outroLottie {
            svg {
              top: 53%;
            }
          }
        }
      }

      .common-copy {
        .section__inner {
          margin: 120px 0 80px;
        }

        .copy {
          &__title {
            font-size: 38px;
            letter-spacing: -0.76px;
          }
        }

        @at-root .navercorp.re-naver-main.lang-en {
          .common-copy .copy__title {
            font-size: 36px;
            letter-spacing: -0.72px;
          }
        }
      }

      .common-card {
        .swiper-button-next, .swiper-button-prev {
          display: none !important;
        }

        .card__txt {
          height: 175px;

          .tit {
            font-size: 22px;
            letter-spacing: -0.44px;
          }

          .desc {
            margin-top: 30px;
            font-size: 14px;
            letter-spacing: -0.28px;
          }
        }
      }

      .intro {
        background: url(../img/mo/naver-main-intro-bg.jpg) no-repeat center;

        .fade-animation {
          &__highlight-text {
            .text {
              font-weight: 700;
              font-size: 18px;
              line-height: 1.4;
              letter-spacing: -0.36px;

              &:last-child {
                margin-top: 12px;
                font-size: 38px;
                line-height: 1.4;
                letter-spacing: -0.76px;
              }
            }

            @at-root .navercorp.re-naver-main.lang-en {
              .intro .fade-animation__highlight-text {
                padding: 0 20px;
              }

              .intro .fade-animation__highlight-text .text:last-child {
                font-size: 36px;
                letter-spacing: -0.72px;
              }
            }
          }
        }
      }

      .beginning {
        .common-copy {
          .section__inner {
            margin-bottom: 50px;
          }
        }

        .common-card {
          .section__inner {
            padding-bottom: 0;
          }

          .card {
            &.beginning-swiper {
              padding: 0 20px;
            }

            &__list {
              width: fit-content;
            }

            &__item {
              width: 275px;

              &:first-child {
                .thumbnail {
                  background-image: url(/img/mo/naver-main-begin1.jpg);
                }
              }

              &:nth-child(2) {
                .thumbnail {
                  background-image: url(/img/mo/naver-main-begin2.jpg);
                }
              }

              &:nth-child(3) {
                .thumbnail {
                  background-image: url(/img/mo/naver-main-begin3.jpg);
                }
              }

              &:nth-child(4) {
                .thumbnail {
                  background-image: url(/img/mo/naver-main-begin4.jpg);
                }
              }

              &:nth-child(5) {
                .thumbnail {
                  background-image: url(/img/mo/naver-main-begin5.jpg);
                }
              }

              &:nth-child(6) {
                .thumbnail {
                  background-image: url(/img/mo/naver-main-begin6.jpg);
                }
              }

              &:nth-child(7) {
                .thumbnail {
                  background-image: url(/img/mo/naver-main-begin7.jpg);
                }
              }

              &:last-child {
                .thumbnail {
                  background-image: url(/img/mo/naver-main-begin8.jpg);
                }
              }
            }
          }
        }
      }

      .again {
        .common-copy {
          padding: 0 20px;

          .section__inner {
            margin-bottom: 80px;
          }
        }

        .sticky-section {
          &__content {
            padding: 0 20px;

            &:last-child {
              margin-bottom: 70px;
            }
          }

          &__text {
            padding: 0;

            .title {
              font-size: 18px;
              letter-spacing: -0.36px;
            }

            .sub-title {
              margin-top: 10px;
              font-size: 24px;
              letter-spacing: -0.48px;
            }
          }

          &__lineup {
            width: 100%;
            margin-top: 50px;

            &-item {
              margin-top: 28px;
            }

            &-box {
              padding: 0;
            }

            &-text {
              width: 265px;

              .date {
                line-height: 1;
              }

              .title, .desc {
                display: none;
              }

              .title-mo {
                display: block;
                margin-top: 2px;
                font-size: 18px;
                line-height: 1.46;
                letter-spacing: -0.36px;
                color: $color-white;
              }
            }

            &-icon {
              margin-left: 14px;

              img {
                width: 56px;
                border-radius: 12px;
              }
            }

            &-ad {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: auto;
              margin-top: 28px;
              border-radius: 0;

              &-box {
                position: relative;
                display: block;
                padding: 0;
                width: auto;
              }

              &-img {
                max-width: 56px;
                margin-left: 14px;
                border-radius: 12px;
              }

              &-text {
                width: 100%;
                max-width: 265px;

                .date {
                  font-size: 14px;
                  line-height: 1;
                  letter-spacing: -0.28px;
                }

                .title, .desc {
                  display: none;
                }

                .title-mo {
                  display: block;
                  margin-top: 2px;
                  font-size: 18px;
                  line-height: 1.46;
                  letter-spacing: -0.36px;
                  color: $color-white;
                }
              }
            }

          }

          @at-root .navercorp.re-naver-main.lang-en {
            .again .sticky-section__text {
              .sub-title {
                font-size: 24px;
                letter-spacing: -0.48px;
              }
            }
          }
        }
      }

      .finish {
        padding-bottom: 120px;

        .common-copy {
          padding: 0 20px;

          .section__inner {
            margin: 70px 0 80px;
          }
        }

        .common-card {
          margin-top: 100px;

          .card {
            margin-top: 50px;

            &__cont {
              margin-right: 0;
              padding: 0 20px;

              &-text {
                width: 100%;
                padding: 0;
              }

              .tit {
                font-size: 26px;
                letter-spacing: -1.04px;
              }

              .tit-mo {
                display: block;
                font-size: 26px;
                letter-spacing: -1.04px;
              }

              .desc {
                margin-top: 10px;
                font-size: 16px;
                letter-spacing: -0.32px;
              }
            }

            &__txt {
              .tit {
                margin-top: 2px;
                font-size: 22px;
                letter-spacing: -0.44px;
              }

              .desc {
                font-size: 18px;
                letter-spacing: -0.36px;
              }
            }

            &.finish-swiper {
              padding: 0 20px;
            }
          }

          &.card01 {
            margin-top: 0;

            .card__item {
              &:first-child {
                background: url(/img/mo/naver-finish1-card1.jpg) no-repeat center;
                background-size: cover;
              }

              &:nth-child(2) {
                background: url(/img/mo/naver-finish1-card2.jpg) no-repeat center;
                background-size: cover;
              }

              &:nth-child(3) {
                background: url(/img/mo/naver-finish1-card3.jpg) no-repeat center;
                background-size: cover;
              }

              &:nth-child(4) {
                background: url(/img/mo/naver-finish1-card4.jpg) no-repeat center;
                background-size: cover;
              }

              &:last-child {
                background: url(/img/mo/naver-finish1-card5.jpg) no-repeat center;
                background-size: cover;
              }
            }
          }

          &.card02 {

            .card__item {
              &:first-child {
                background: url(/img/mo/naver-finish2-card1.jpg) no-repeat center;
                background-size: cover;
              }

              &:nth-child(2) {
                background: url(/img/mo/naver-finish2-card2.jpg) no-repeat center;
                background-size: cover;
              }

              &:nth-child(3) {
                background: url(/img/mo/naver-finish2-card3.jpg) no-repeat center;
                background-size: cover;
              }

              &:nth-child(4) {
                background: url(/img/mo/naver-finish2-card4.jpg) no-repeat center;
                background-size: cover;
              }

              &:last-child {
                background: url(/img/mo/naver-finish2-card5.jpg) no-repeat center;
                background-size: cover;
              }
            }
          }

          &.card03 {
            .card__item {
              &:first-child {
                background: url(/img/mo/naver-finish3-card1.jpg) no-repeat center;
                background-size: cover;
              }

              &:nth-child(2) {
                background: url(/img/mo/naver-finish3-card2.jpg) no-repeat center;
                background-size: cover;
              }

              &:nth-child(3) {
                background: url(/img/mo/naver-finish3-card3.jpg) no-repeat center;
                background-size: cover;
              }

              &:nth-child(4) {
                background: url(/img/mo/naver-finish3-card4.jpg) no-repeat center;
                background-size: cover;
              }

              &:nth-child(5) {
                background: url(/img/mo/naver-finish3-card5.jpg) no-repeat center;
                background-size: cover;
              }

              &:last-child {
                background: url(/img/mo/naver-finish3-card6.jpg) no-repeat center;
                background-size: cover;
              }
            }

            .card__cont {
              .tit {
                display: none;
              }
            }
          }
        }
      }

      .ending {
        .fade-animation {
          &__highlight-text {
            .text {
              font-size: 38px;
              line-height: 1.4;
              letter-spacing: -0.76px;
            }

            .extend-wrap {
              flex: 4;
            }

            &.kor {
              height: 159px;

              &.is-show {
                transform: translateY(-65%);
              }

              &.is-extended {
                height: 318px;
              }
            }

            &.eng {
              height: 100px;

              &.is-show {
                transform: translateY(-70%);
              }
            }

            @at-root .navercorp.re-naver-main.lang-en {
              .ending .fade-animation__highlight-text.eng.is-extended {
                height: 250px;
              }

              .ending .fade-animation__highlight-text .text {
                font-size: 36px;
                letter-spacing: -0.72px;
              }
            }
          }

          @at-root .navercorp.re-naver-main.lang-en {
            .ending .fade-animation__box {
              top: 45%;
            }
          }
        }
      }

      .info {
        flex-direction: column;

        &__img {
          width: 100%;

          .img {
            padding-top: 100%;
          }
        }

        &__txt {
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;

          .txt__list {
            margin-top: 114px;
          }

          .txt__item {
            padding: 14px 0;

            .tit,
            .desc {
              font-size: 12px;
              line-height: 1.83;
              letter-spacing: -0.24px;
            }

            .tit {
              width: 86px;
              white-space: nowrap;
            }

            .desc {
              width: 100%;
              max-width: 220px;
              margin-left: 30px;
            }
          }
        }
      }

      .relate-content {
        padding: 36px 0 80px;
      }
    }
  }
}
