.#{$component-name} {
  &.commerce {
    .section {
      &__visual {
        background-image: url(/img/pc/service-commerce-main.jpg);
      }

      &__outlink {
        margin-top: 133px;
      }

      &__img {
        margin-top: 100px;
      }
    }

    .service__bg {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .service__img-wrap {

      &.shopping-main,
      &.price-main {
        .service__bg {
          width: 100%;
          height: auto;
          padding-top: 36.25%;
          background-image: url(/img/pc/service-commerce-shopping-bg.png);
        }

        .service__img {
          width: 20.652%;
        }
      }

      &.price-main {
        .service__bg {
          background-image: url(/img/pc/service-commerce-shopping-bg.jpg);
        }
      }

      &.is-hide {
        .service__img {
          border: 0;
        }
      }
    }

    .section__mobile-frame {
      &.is-hide {
        border: 0;
      }
    }

    .section-program {
      .service {
        &__detail {
          display: flex;
          justify-content: space-between;
          max-width: 1118px;
          margin: 100px auto 0;
          padding-top: 80px;
          border-top: 1px solid var(--border01);
          text-align: left;

          &:last-of-type {
            margin-top: 73px;
          }

          &-right {
            width: 549px;
          }

          &-list {
            margin-top: 50px;
          }

          &-link {
            width: 100%;
            padding: 26px 0;
            border-top: 1px solid var(--border01);

            &:hover {
              .outlink-icon {
                background-color: $color-point;
              }
            }

            .title {
              display: block;
              font-weight: 800;
              font-size: 22px;
              line-height: 1.55;
              letter-spacing: -0.6px;
            }

            .outlink-icon {
              position: relative;
              width: 30px;
              height: 30px;
              margin-right: 20px;
              border-radius: 50%;
              background-color: $color-default;
              background-image: url(/img/common/ic-outlink-white.svg);
              background-repeat: no-repeat;
              background-size: contain;
              transition: all 0.3s;

              @at-root .theme-dark & {
                background-color: #424245;

                &:hover {
                  background-color: $color-point;
                }
              }
            }

            .desc {
              display: block;
              margin-top: 12px;
              font-size: 16px;
              line-height: 1.75;
              letter-spacing: -0.5px;
            }
          }

          &-head {
            display: flex;
            justify-content: space-between;
          }

          &-img {
            position: relative;
            height: 365px;
            background-color: var(--privacyBg);

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 239px;
              height: 239px;
              transform: translate(-50%, -50%);
            }
          }

          .service__text {
            margin-top: 21px;
            font-weight: 700;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }
        }
      }
    }

    .commerce {
      &__title {
        font-weight: 800;
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -1px;
        color: var(--default);
      }

      &__shopping {
        max-width: 1118px;
        margin: 200px auto 0;
        padding-top: 101px;
        text-align: left;
        border-top: 1px solid var(--border01);

        &-list {
          margin-top: 80px;
        }

        &-item {
          display: flex;
          justify-content: space-between;

          &+.commerce__shopping-item {
            margin-top: 100px;
          }

          &:first-child,
          &:nth-child(2) {
            .section__mobile-frame {
              width: 372px;
              height: 618px;
              border-radius: 47px;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }

          .info__title {
            margin-top: 0;
            font-weight: 800;
            font-size: 22px;
            line-height: 1.91;
            letter-spacing: -0.6px;
            color: var(--default);
          }

          .info__text {
            margin-top: 20px;
            font-weight: 700;
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
            color: var(--color-sub);
          }

          .section__mobile-frame-wrap {
            overflow: hidden;
            width: 549px;
            background-color: var(--privacyBg);

            &.is-active {
              img {
                animation: slideUpCommerce 4s linear infinite forwards;
                animation-timing-function: cubic-bezier(0.65, -0.02, 0.45, 0.98);
              }
            }
          }

          &:last-child {
            .section__mobile-frame-wrap {
              &.is-active {
                img {
                  animation: slideUpCommerce2 4s linear infinite forwards;
                  animation-timing-function: cubic-bezier(0.65, -0.02, 0.45, 0.98);
                }
              }
            }
          }

          .section__mobile-frame {
            &.is-active {
              display: flex;
              position: static;
              width: 300%;
              height: 100%;
              transform: translateX(0%);
              animation: slideNext3 2.7s linear forwards;
              animation-timing-function: cubic-bezier(0.8, -0.01, 0.27, 1);

              .img {
                position: relative;
                flex-shrink: 0;
                width: 33.35%;
                height: 100%;

                img {
                  position: absolute;
                  bottom: -205px;
                  left: 50%;
                  width: 430px;
                  transform: translateX(-50%);
                }
              }
            }
          }

          .commerce-swiper {
            position: relative;
            left: -55px;
            padding: 60px 55px 0;

            .swiper-slide {
              margin-left: 55px;
              margin-right: 55px;
            }
          }
        }

        &-img {
          width: 549px;

          .white {
            display: block;
          }

          .dark {
            display: none;
          }
        }
      }

      &__ai {
        max-width: 1118px;
        margin: 200px auto 0;
        text-align: left;

        &-text {
          font-weight: 700;
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
          color: var(--default);

          em {
            display: block;
            font-weight: 800;
          }
        }

        .service__bg {
          padding-top: 40.251%;
          background-image: url(/img/pc/service-commerce-ai-bg.png);
        }

        .section__swiper {
          overflow: hidden;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 26.6%;
          height: 100%;
          border: 6px solid #393939;
          border-radius: 30px;
          transform: translate(-50%, -50%);
        }

        .swiper-slide {
          &.is-active {
            display: flex;
            width: 300%;
            animation: slideNext3 2.7s linear forwards;

            .img {
              flex-shrink: 0;
              width: 33.35%;
            }
          }
        }

        .service__img-wrap {
          padding-top: 56.3%;
          margin-top: 90px;
        }
      }

      &__brand {
        max-width: 1118px;
        margin: 80px auto 0;
        text-align: left;

        &-list {
          margin-top: 60px;
        }

        &-item {
          border-top: 1px solid var(--border01);
          padding: 60px 0 80px;

          .service__bg {
            padding-top: 40.251%;
          }

          &:first-child {
            .service__bg {
              background-image: url(/img/pc/service-commerce-brand-bg01.png);
            }

            .swiper-slide {
              img {
                height: 100%;
              }
            }
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            .service__img {
              width: 25.6%;
              border: 6px solid #2f2e30;
              border-radius: 30px;
              background-color: #2f2e30;
            }

            .service__img-wrap {
              padding-top: 54%;
            }
          }

          &:nth-child(2) {
            .service__bg {
              background-image: url(/img/pc/service-commerce-brand-bg02.jpg);
            }
          }

          &:nth-child(3) {
            .service__bg {
              background-image: url(/img/pc/service-commerce-brand-bg03.png);
            }
          }

          &:nth-child(4) {
            .service__bg {
              padding-top: 41.8%;
              background-image: url(/img/pc/service-commerce-brand-bg04.png);
            }
          }

          &:nth-child(5) {
            .service__bg {
              background-image: url(/img/pc/service-commerce-brand-bg05.png);
            }
          }

          &:last-child {
            .service__bg {
              background-image: url(/img/pc/service-commerce-brand-bg06.png);
            }

            .service__img-wrap {
              padding-top: 53.734%;
            }

            .service__img {
              width: 25.4%;
            }
          }
        }

        &-head {
          display: flex;
          justify-content: space-between;
        }

        &-info {
          display: flex;

          .service__icon {
            flex: none;
            width: 80px;
            height: 80px;
            margin-right: 34px;
          }

          .title {
            font-weight: 700;
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
            color: var(--default);

            em {
              font-weight: 800;
            }
          }
        }

        .section__swiper {
          overflow: hidden;
          position: absolute;
          top: 50%;
          left: 50%;
          // width: 276px;
          width: 25.6%;
          height: 100%;
          border: 6px solid #393939;
          border-radius: 30px;
          transform: translate(-50%, -50%);
        }

        .swiper-slide {
          &.is-active {
            display: flex;
            width: 300%;
            animation: slideNext3 2.7s linear forwards;

            .img {
              flex-shrink: 0;
              width: 33.35%;
            }
          }
        }

        .service__img-wrap {
          // height: 583px;
          padding-top: 54%;
          margin-top: 90px;
          background-color: #fff;
        }

        .service__img {
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 0;
          width: 24.687%;
          height: 100%;
          transform: translate(-50%, -50%);
        }
      }

      &__solution {
        max-width: 1118px;
        margin: 80px auto 0;
        text-align: left;

        &-list {
          margin-top: 60px;
          border-top: 1px solid var(--border01);
        }

        &-link {
          display: flex;
          justify-content: space-between;
          padding-top: 80px;
          transition: color 0.3s;

          .category {
            font-weight: 700;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: -0.3px;
          }

          .name {
            margin-top: 8px;
            font-weight: 800;
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;
            transition: all 0.3s;

            &:hover {
              color: $color-point;

              &:after {
                background: url(../img/common/btn-outlink-on.svg);
                background-size: contain;
                vertical-align: middle;
                transition: background 0.3s;
                content: '';
              }
            }

            &:after {
              display: inline-block;
              width: 25px;
              height: 25px;
              margin-left: 4px;
              background-image: var(--btn-outlink);
              background-repeat: no-repeat;
              background-size: contain;
              vertical-align: middle;
              content: '';
            }
          }

          .text {
            margin-top: 16px;
            font-size: 16px;
            line-height: 1.88;
            letter-spacing: -0.5px;
            color: #6e6e73;
          }

          .img {
            position: relative;
            width: 549px;
            height: 365px;
            background-color: var(--privacyBg);

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 239px;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  @keyframes slideUpCommerce {
    0% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(-68.5%);
    }
  }

  @keyframes slideUpCommerce2 {
    0% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(-70%);
    }
  }
}

.theme-dark {
  .navercorp.commerce {
    .commerce {
      &__shopping {
        &-img {
          .white {
            display: none;
          }

          .dark {
            display: block;
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.commerce {
      .section__outlink {
        margin-top: 72px;
      }

      .service__img-wrap {

        &.shopping-main,
        &.price-main {
          .service__bg {
            padding-top: 36.25%;
          }
        }
      }

      .section-program {
        .service__detail {
          max-width: 944px;
          padding-top: 60px;

          &:last-of-type {
            margin-top: 53px;
          }

          &-right {
            width: 460px;
          }

          &-list {
            margin-top: 40px;
          }

          &-link {
            .outlink-icon {
              margin-right: 10px;
            }
          }

          &-img {
            height: 306px;

            img {
              width: 200px;
              height: 200px;
            }
          }
        }
      }

      .commerce {
        &__shopping {
          max-width: 944px;
          margin-top: 80px;
          padding-top: 80px;

          &-item {
            &+.commerce__shopping-item {
              margin-top: 80px;
            }

            &:first-child,
            &:nth-child(2) {
              .section__mobile-frame {
                width: 313px;
                height: 520px;
                border-radius: 40px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }

            .section__mobile-frame {
              width: 360px;
              height: 518px;

              &.is-active {
                .img {
                  img {
                    bottom: -174px;
                    width: 363px;
                  }
                }
              }
            }

            .section__mobile-frame-wrap {
              width: 460px;
              height: 580px;

              .commerce-swiper {
                left: -50px;
                padding: 60px 50px 0;

                .swiper-slide {
                  margin-left: 50px;
                  margin-right: 50px;
                }
              }
            }
          }

          &-img {
            width: 460px;
          }
        }

        &__ai {
          max-width: 944px;
          margin-top: 140px;

          .service__bg {
            padding-top: 40.255%;
          }

          .section__swiper {
            width: 23.55%;
          }

          .service__img-wrap {
            padding-top: 49.5%;
          }
        }

        &__brand {
          max-width: 944px;
          margin-top: 140px;

          &-item {
            .service__bg {
              padding-top: 40.255%;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              .service__img {
                width: 23.55%;
              }

              .service__img-wrap {
                padding-top: 49.5%;
              }
            }

            &:nth-child(4) {
              .service__bg {
                padding-top: 41.77%;
              }
            }

            &:last-child {
              .service__img {
                width: 23.67%;
              }

              .service__img-wrap {
                padding-top: 50%;
              }
            }
          }

          &-info {
            .service__icon {
              margin-right: 30px;
            }
          }

          .section__swiper {
            width: 23.5%;

            &:after {
              top: 53%;
            }
          }

          .service__img-wrap {
            padding-top: 49.62%;
          }

          .service__img {
            width: 23.306%;
          }
        }

        &__solution {
          max-width: 944px;
          margin-top: 60px;

          &-link {
            padding-top: 60px;

            .img {
              width: 460px;
              height: 306px;

              img {
                width: 200px;
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.commerce {
      .section__outlink {
        margin-top: 60px;
      }

      .service__img-wrap {

        &.shopping-main,
        &.price-main {
          .service__bg {
            padding-top: 41.016%;
          }

          .service__img {
            width: 24.7%;
          }
        }
      }

      .section__img {
        margin-top: 90px;
      }

      .section-shopping {
        .service__bg {
          width: auto;
          height: 420px;

          img {
            width: auto;
            height: 100%;
          }
        }
      }

      .section-program {
        .service__detail {
          max-width: none;
          margin: 80px 112px 0;

          &:last-of-type {
            margin-top: 20px;
          }

          &-right {
            width: 390px;
          }

          &-list {
            margin-top: 30px;
          }

          &-link {
            padding: 20px 0;

            .title {
              font-size: 18px;
              line-height: 1.89;
              letter-spacing: -0.5px;
            }

            .desc {
              margin-top: 10px;
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }
          }

          &-img {
            height: 259px;

            img {
              width: 170px;
              height: 170px;
            }
          }

          .service__text {
            margin-top: 20px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }
      }

      .commerce {
        &__title {
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
        }

        &__shopping {
          max-width: none;
          margin: 80px 112px 0;
          padding-top: 60px;

          &-list {
            margin-top: 60px;
          }

          &-item {
            &+.commerce__shopping-item {
              margin-top: 60px;
            }

            &:first-child,
            &:nth-child(2) {
              .section__mobile-frame {
                width: 266px;
                height: 441px;
                border-radius: 35px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }

            .info__title {
              font-size: 18px;
              line-height: 1.89;
              letter-spacing: -0.5px;
            }

            .info__text {
              margin-top: 12px;
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
              width: 370px;
            }

            .section__mobile-frame-wrap {
              width: 390px;
              height: 492px;

              .commerce-swiper {
                left: -39px;
                padding: 50px 40px 0;

                .swiper-slide {
                  margin-left: 40px;
                  margin-right: 40px;
                }
              }
            }

            .section__mobile-frame {
              width: 306px;
              height: 439px;

              &.is-active {
                .img {
                  img {
                    bottom: -142px;
                    width: 306px;
                  }
                }
              }
            }
          }

          &-img {
            width: 390px;
          }
        }

        &__ai {
          max-width: none;
          margin-top: 120px;
          padding: 0 30px;

          &-text {
            font-size: 22px;
            line-height: 1.64;
            letter-spacing: -0.6px;
          }

          .section__swiper {
            width: 24.8%;
            border-radius: 34px;
          }

          .service__img-wrap {
            padding-top: 52.1%;
            margin-top: 64px;
          }

          .service__bg {
            width: 100%;
            height: auto;
            padding-top: 40.25%;
          }
        }

        &__brand {
          max-width: none;
          margin-top: 120px;
          padding: 0 30px;

          .section__swiper {
            width: 24.69%;
            border-radius: 34px;
          }

          .service__img-wrap {
            padding-top: 52.075%;
            margin-top: 64px;
          }

          .service__img {
            width: 24.69%;
          }

          &-list {
            margin-top: 50px;
          }

          &-item {
            padding: 50px 0 72px;

            .service__bg {
              width: 100%;
              height: auto;
              padding-top: 40.25%;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              .service__img {
                width: 24.73%;
              }

              .service__img-wrap {
                padding-top: 52.075%;
              }
            }

            &:nth-child(2) {
              .service__bg {
                img {
                  width: 100%;
                  height: auto;
                }
              }
            }

            &:nth-child(4) {
              .service__bg {
                padding-top: 41.71%;

                img {
                  width: 100%;
                  height: auto;
                }
              }
            }

            &:last-child {
              .service__img-wrap {
                padding-top: 52.25%;
              }

              .service__img {
                width: 24.81%;
              }
            }
          }

          &-info {
            .service__icon {
              margin-right: 33px;
            }

            .title {
              font-size: 22px;
              line-height: 1.64;
              letter-spacing: -0.6px;
            }
          }
        }

        &__solution {
          max-width: none;
          padding: 0 112px;

          &-list {
            margin-top: 50px;
          }

          &-item {
            &:first-child {
              .commerce__solution-link {
                padding-top: 80px;
              }
            }
          }

          &-link {
            padding-top: 30px;

            .category {
              font-size: 12px;
              line-height: 2.17;
              letter-spacing: normal;
            }

            .name {
              margin-top: 4px;
              font-size: 18px;
              line-height: 1.89;
              letter-spacing: -0.5px;
            }

            .text {
              margin-top: 9px;
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }

            .img {
              width: 390px;
              height: 259px;

              img {
                width: 170px;
                height: 170px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.commerce {
      .service__img-wrap {

        &.shopping-main,
        &.price-main {
          .service__bg {
            padding-top: 43.62%;
          }

          .service__img {
            width: 26.1%;
          }
        }
      }

      .section__img {
        margin-top: 60px;
      }

      .section-program {
        .service__detail {
          margin: 60px 30px 0;

          &-right {
            width: 348px;
          }

          &-link {
            .outlink-icon {
              width: 24px;
              height: 24px;
            }

            .desc {
              margin-top: 8px;
            }
          }

          &-head {
            align-items: center;
          }

          &-img {
            height: 231px;

            img {
              width: 152px;
              height: 152px;
            }
          }

          .commerce__title {
            font-size: 22px;
            line-height: 1.73;
            letter-spacing: -0.4px;
          }

          .service__text {
            margin-top: 10px;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.4px;
          }
        }
      }

      .commerce {
        &__shopping {
          margin: 80px 30px 0;
          padding-top: 50px;

          &-list {
            margin-top: 50px;
          }

          &-item {
            &+.commerce__shopping-item {
              margin-top: 40px;
            }

            &:first-child,
            &:nth-child(2) {
              .section__mobile-frame {
                width: 239px;
                height: 394px;
                border-radius: 35px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }

            .section__mobile-frame-wrap {
              width: 348px;
              height: 439px;

              .commerce-swiper {
                left: -28px;
                padding: 50px 37px 0;

                .swiper-slide {
                  margin-left: 30px;
                  margin-right: 30px;
                }
              }
            }

            .section__mobile-frame {
              width: 274px;
              height: 394px;

              &.is-active {
                .img {
                  img {
                    bottom: -129px;
                    width: 270px;
                  }
                }
              }
            }
          }

          &-img {
            width: 348px;
          }
        }

        &__ai {
          margin-top: 100px;

          .section__swiper {
            width: 21.91%;
            border-width: 4px;
            border-radius: 20px;
          }

          .service__img-wrap {
            padding-top: 46%;
            margin-top: 61px;
          }

          .service__img {
            width: 21.753%;
          }

          .service__bg {
            padding-top: 40.255%;
          }
        }

        &__brand {
          margin-top: 100px;

          .section__swiper {
            width: 22.32%;
            border-width: 4px;
            border-radius: 20px;
          }

          .service__img-wrap {
            padding-top: 47%;
            margin-top: 61px;
          }

          .service__img {
            width: 21.753%;
          }

          &-item {
            padding: 34px 0 60px;

            .service__bg {
              padding-top: 40.255%;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              .service__img {
                width: 22.32%;
                border: 4px solid #2f2e30;
                border-radius: 20px;
              }

              .service__img-wrap {
                padding-top: 47%;
              }
            }

            &:nth-child(4) {
              .service__bg {
                padding-top: 41.67%;
              }
            }

            &:last-child {
              .service__img-wrap {
                padding-top: 47%;
              }

              .service__img {
                width: 22.2%;
              }
            }
          }

          &-head {
            display: block;
          }

          &-info {
            .service__icon {
              width: 67px;
              height: 67px;
              margin-left: 0;
              margin-right: 27px;
            }
          }

          .service__btn {
            margin-top: 30px;
            margin-left: 94px;
            padding-left: 37px;
          }
        }

        &__solution {
          margin-top: 100px;
          padding: 0 30px;

          &-list {
            display: flex;
            flex-wrap: wrap;
            padding-top: 30px;
          }

          &-item {
            width: calc((100% - 12px) / 2);
            margin-right: 12px;
            margin-top: 60px;

            &:first-child {
              .commerce__solution-link {
                padding-top: 0;
              }
            }

            &:first-child,
            &:nth-child(2) {
              margin-top: 0;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }

          &-link {
            flex-direction: column-reverse;
            padding-top: 0;

            .category {
              display: block;
              margin-top: 16px;
            }

            .img {
              width: 100%;
              height: 231px;

              img {
                width: 152px;
                height: 152px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.commerce {
      .section {
        &__visual {
          background-image: url(/img/mo/service-commerce-main.jpg);
        }
      }

      .service__img-wrap {

        &.shopping-main,
        &.price-main {
          .service__bg {
            width: 100%;
            height: auto;
            padding-top: 95.67%;
            background-image: url(/img/mo/service-commerce-shopping-bg.png);
          }

          .service__img {
            width: 55%;
          }
        }

        &.price-main {
          .service__bg {
            background-image: url(/img/mo/service-commerce-shopping-bg.jpg);
          }
        }
      }

      .section-program {
        .service__detail {
          display: block;
          margin: 60px 20px 0;

          .service__text {
            padding: 0;
          }

          &-right {
            width: auto;
          }

          &-img {
            height: 222px;
            margin-top: 40px;

            img {
              width: 146px;
              height: 146px;
            }
          }
        }
      }

      .commerce {
        &__title {
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
        }

        &__shopping {
          margin: 60px 20px 0;
          padding-top: 40px;

          &-list {
            margin-top: 40px;
          }

          &-item {
            display: block;

            &+.commerce__shopping-item {
              margin-top: 60px;
            }

            &:first-child,
            &:nth-child(2) {
              .section__mobile-frame {
                width: 229px;
                height: 378px;
                border-radius: 28px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }

            .section__mobile-frame-wrap {
              width: 100%;
              height: 422px;
              margin-top: 40px;

              .commerce-swiper {
                left: -32px;
                padding: 50px 30px 0;

                .swiper-slide {
                  margin-left: 35px;
                  margin-right: 35px;
                }
              }
            }

            .section__mobile-frame {
              width: 263px;
              height: 377px;

              &.is-active {
                .img {
                  img {
                    bottom: -125px;
                    width: 263px;
                  }
                }
              }
            }

            .info__text {
              width: 100%;
              margin-top: 10px;
              font-size: 16px;
              line-height: 1.75;
              letter-spacing: -0.4px;
            }
          }

          &-img {
            width: 100%;
            margin-top: 40px;
          }
        }

        &__ai {
          padding: 0 20px;

          &-text {
            margin-top: 22px;
          }

          .section__swiper {
            position: relative;
            top: auto;
            left: auto;
            margin: 0 auto;
            transform: translate(0, 0);
            width: 53.334%;
            border-width: 5px;
            border-radius: 28px;
          }

          .service__img-wrap {
            position: relative;
            // padding-top: 125.971%;
            margin: 61px -20px 0;
            padding: 0;
          }

          .service__img {
            width: 53.334%;
          }

          .service__bg {
            padding-top: 94.67%;
            background-image: url(/img/mo/service-commerce-ai-bg.png);
          }
        }

        &__brand {
          padding: 0 20px;

          .commerce__title {
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
          }

          .section__swiper {
            width: 53.334%;
            border-width: 5px;
            border-radius: 28px;
          }

          .service__img-wrap {
            padding-top: 125.971%;
            margin: 61px -20px 0;
          }

          .service__img {
            width: 53.334%;
          }

          &-list {
            margin-top: 38px;
          }

          &-item {
            padding: 63px 0 60px;

            .service__bg {
              padding-top: 94.67%;
            }

            &:first-child {
              .service__bg {
                background-image: url(/img/mo/service-commerce-brand-bg01.png);
              }
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              .service__img {
                position: relative;
                transform: translate(0, 0);
                top: auto;
                left: auto;
                width: 55%;
                margin: 0 auto;
                border: 5px solid #2f2e30;
                border-radius: 28px;
              }

              .service__img-wrap {
                // padding-top: 129%;
                position: relative;
                padding: 0;
              }
            }

            &:nth-child(2) {
              .service__bg {
                padding-top: 97.87%;
                background-image: url(/img/mo/service-commerce-brand-bg02.png);
              }
            }

            &:nth-child(3) {
              .service__bg {
                background-image: url(/img/mo/service-commerce-brand-bg03.png);
              }
            }

            &:nth-child(4) {
              .service__bg {
                padding-top: 94.67%;
                background-image: url(/img/mo/service-commerce-brand-bg04.png);
              }
            }

            &:nth-child(5) {
              .service__bg {
                background-image: url(/img/mo/service-commerce-brand-bg05.png);
              }

              .service__img-wrap {
                padding-top: 126.3%;
              }

              .service__img {
                width: 53.4%;
              }
            }

            &:last-child {
              .service__bg {
                background-image: url(/img/mo/service-commerce-brand-bg06.png);
              }

              .service__img-wrap {
                padding-top: 126.3%;
              }

              .service__img {
                width: 53.4%;
              }
            }
          }

          &-info {
            display: block;

            .service__icon {
              margin-right: 0;
            }

            .title {
              margin-top: 22px;
            }
          }

          .service__btn {
            margin-top: 19px;
            margin-left: 0;
          }
        }

        &__solution {
          margin-top: 62px;
          padding: 0 20px;

          .commerce__title {
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
          }

          &-list {
            display: block;
            margin-top: 58px;
          }

          &-item {
            width: 100%;
            margin-right: 0;

            &:nth-child(2) {
              margin-top: 60px;
            }
          }

          &-link {
            .category {
              margin-top: 16px;
              font-size: 14px;
              line-height: 1.71;
              letter-spacing: -0.3px;
            }

            .name {
              &::after {
                margin-top: -2px;
              }
            }

            .text {
              margin-top: 11px;
            }

            .img {
              height: 222px;

              img {
                width: 146px;
                height: 146px;
              }
            }
          }
        }
      }
    }
  }
}
