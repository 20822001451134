.#{$component-name} {
  &.main {
    &.lang-en {
      .cont-summary {
        .service {
          background-color: #0dcb67;

          .cont__link {
            color: $color-default;
          }

          .title {
            display: block;
            max-width: 50%;
            text-transform: uppercase;
          }

          .thumb-box {
            top: auto;
            left: auto;
            right: 20px;
            bottom: 20px;
            width: 120px;
            height: 80px;
          }

          &:hover {
            .thumbnail {
              transform: none;
            }
          }
        }
      }
    }

    .container {
      padding-top: 0;
    }

    .main-content {
      &__inner {
        display: flex;
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
      }

      &__title {
        flex-shrink: 0;
        position: relative;
        z-index: 10;
        width: 340px;
        font-size: 38px;
        font-weight: 700;
        line-height: 52px;
        letter-spacing: -1.5px;
        color: var(--default);

        &::after {
          display: block;
          width: 53px;
          height: 46px;
          margin-top: 37px;
          background: var(--ic-title-arrow) no-repeat center;
          background-size: contain;
          content: '';
        }

        strong {
          display: block;
        }

        .time {
          display: flex;
          margin-top: 20px;
          letter-spacing: 0;

          .colon {
            animation: blink 1s step-end infinite;
          }
        }

        .btn-arrow {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 53px;
          height: 60px;
          background-image: var(--ic-arrow-down);
          background-size: contain;
          transition: background .3s;

          &:hover {
            background-image: var(--ic-arrow-down-hover);
          }
        }
      }
    }

    .cont__area {
      flex: 1;
    }

    .cont__link {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .cont-summary {
      padding: 150px 60px 80px;

      .main-content__title {
        &::after {
          content: none;
        }
      }

      .slogan {
        position: relative;

        &__link {
          display: inline-flex;
          height: 138px;
          margin-bottom: 20px;

          &:hover {
            .outlink {
              &::after {
                background: url(/img/common/ic-outlink-circle-green-hover.png) no-repeat center;
                background-size: contain;
              }
            }
          }
        }

        .lottie {
          &__wrap {
            width: 300px;
            height: 118px;
          }
          
          &__container {
            height: 100%;

            &#sloganLottie1 {
              height: 45px;

              svg {
                margin-left: -55px;
              }
            }

            &#sloganLottie2 {
              height: 58px;
              margin-top: 16px;
              opacity: 0;
              
              &.is-show {
                opacity: 1;
                transition: opacity 0.5s ease-in-out;
              }
            }
          }
        }

        .outlink {
          margin-left: 14px;
          padding-top: 74px;
          opacity: 0;
          
          &::after {
            display: block;
            width: 30px;
            height: 30px;
            background: url(/img/common/ic-outlink-circle-black.png) no-repeat center;
            background-size: contain;
            content: '';
          }
          
          &.is-show {
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }
        }
      }

      .cont__area {
        z-index: 2;
      }

      .cont__list {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 49.827% 1fr 1fr;
        grid-template-rows: 601px 187px;
      }

      .cont__item {
        position: relative;
        background-color: var(--mainBg);
        transition: background .3s;

        &:hover {
          // background-color: $color-point;

          .thumbnail {
            transform: scale(1.05);
          }
        }

        .head, .category, .title {
          display: block;
        }

        .text-wrap {
          z-index: 10;
        }

        .head, .category {
          z-index: 10;
          font-size: 14px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: -.3px;
          // color: var(--default);
        }

        .title {
          font-size: 22px;
          font-weight: 800;
          line-height: 34px;
          letter-spacing: -.6px;
          // color: var(--default);
        }

        .desc {
          z-index: 10;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: -.3px;
          // color: var(--default);
        }

        .outlink {
          position: absolute;
          right: 16px;
          bottom: 16px;
          transition: all .3s;

          &::after {
            display: block;
            width: 24px;
            height: 24px;
            background: var(--ic-outlink-circle) no-repeat center;
            background-size: contain;
            content: '';
          }
        }

        &.stock, &.ir {
          .head, .title, .desc {
            color: var(--default);
            transition: color .3s;
          }

          &:hover {
            .head, .title, .desc {
              color: $color-point;
            }
          }
        }

        &.news {
          &:hover {
            .outlink {
              &::after {
                background: url(/img/common/ic-outlink-circle-black-hover.png) no-repeat center;
                background-size: contain;
              }
            }
          }
        }

        &.ir {
          &:hover {
            .outlink {
              &::after {
                background: url(/img/common/ic-outlink-circle-hover.png) no-repeat center;
                background-size: contain;
              }
            }
          }
        }


        &.recruit {
          .outlink {
            &:after {
              background: url(/img/common/ic-outlink-circle.png) no-repeat center;
              background-size: contain;
            }
          }
        }

        &.stock {
          &:hover {
            .num-info {
              &.up {
                &::after {
                  background: var(--ic-arrow-down-hover) no-repeat center;
                  transform: rotate(180deg);
                  background-size: contain;
                }
              }
            }
          }
        }


      }

      .cont__link {
        padding: 16px 40px 16px 16px;
        justify-content: space-between;
        color: $color-white;
      }

      .thumb-box {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .thumbnail {
          width: 100%;
          height: 100%;
          background: no-repeat center;
          background-size: cover;
          transition: transform .3s;
        }
      }

      .text-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .media {
        .title {
          margin-top: 14px;
          font-size: 26px;
          line-height: 38px;
          letter-spacing: -0.8px;
          text-transform: uppercase;
        }
      }

      .news {
        .cont__link {
          padding: 0;
          transition: all .3s;

          &:hover {
            color: $color-point;
          }
        }

        .thumb-box {
          position: relative;
          height: 479px;
        }

        .text-box {
          flex: 1;
          padding: 16px 40px 16px 16px;
          background-color: var(--newslistBg);
        }

        .title {
          @include multi-ellipsis(2);
          margin-top: 20px;
          font-size: 32px;
          font-weight: 700;
          line-height: 48px;
          letter-spacing: -1px;
        }

        .date {
          font-size: 14px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: -.3px;
          color: #86868b;
        }
      }

      .story {
        .title {
          @include multi-ellipsis(2);
          margin-top: 10px;
          font-size: 26px;
          line-height: 38px;
          letter-spacing: -.8px;
        }
      }

      .ad {
        .head {
          display: flex;
        }

        .title {
          display: none;
        }
      }

      .recruit {
        &.is-hiring {
          background-color: #022be9;

          &:hover {
            background-color: $color-point;
          }
        }
      }

      .stock {
        &:hover {
          .cont__link {
            color: $color-white;
          }
        }

        .cont__link {
          padding: 16px;
          color: $color-default;
        }

        .num-info {
          display: block;
          font-size: 14px;
          font-weight: 700;
          line-height: 28px;

          &::before {
            display: inline-block;
            width: 20px;
            height: 20px;
            vertical-align: middle;
            content: '';
          }

          &::after {
            position: absolute;
            right: 20px;
            bottom: 20px;
            width: 32px;
            height: 36px;
            content: '';
          }

          &.up {
            color: #ff2c2c;

            &::before {
              background: url(/img/common/ic-stock-up.png) no-repeat center;
              background-size: contain;
            }

            &::after {
              background: var(--ic-arrow-primary) no-repeat center;
              background-size: contain;
            }
          }

          &.down {
            color: #4078ff;

            &::before {
              background: url(/img/common/ic-stock-down.png) no-repeat center;
              background-size: contain;
            }

            &::after {
              background: var(--ic-arrow-primary) no-repeat center;
              background-size: contain;
              transform: rotate(180deg);
            }
          }
        }

        .count {
          font-size: 38px;
          font-weight: 700;
          line-height: 54px;
          letter-spacing: -1.5px;
          color: var(--default);
        }
      }

      .ir {
        .title {
          margin-top: 6px;
        }

        .cont__link {
          color: $color-default;
        }

        &:hover {
          .cont__link {
            color: $color-white;
          }
        }
      }

      //type 스타일
      .cont__area {
        &.type-a {
          .story, .ad {
            display: none;
          }

          .media {
            order: 1;
            grid-column: 1/3;
            grid-row: 1/2;

            .head, .title {
              display: none;
            }
          }

          .news {
            order: 2;
            grid-column: 3/4;
            grid-row: 1/2;

            .thumb-box {
              height: 417px;
            }

            .title {
              margin-top: 6px;
              font-size: 18px;
              line-height: 30px;
              letter-spacing: -.5px;
            }

            .outlink {
              display: none;
            }
          }

          .recruit {
            order: 3;
            grid-column: 1/2;
            grid-row: 2/3;
          }

          .stock {
            order: 4;
            grid-column: 2/3;
            grid-row: 2/3;
          }

          .ir {
            order: 5;
          }
        }

        &.type-b {
          .cont__list {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 394px 187px 187px;
          }

          .media {
            display: none;
          }

          .news {
            order: 1;
            grid-column: 1/3;
            grid-row: 1/4;
          }

          .story {
            order: 2;
            grid-column: 3/5;
            grid-row: 1/2;
          }

          .ad {
            order: 3;
          }

          .recruit {
            order: 5;
          }

          .stock {
            order: 6;
          }

          .ir {
            order: 4;
          }
        }

        &.type-c {
          .cont__list {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(4, 187px);
          }

          .media {
            order: 2;
            grid-column: 3/4;
            grid-row: 1/3;
          }

          .news {
            order: 1;
            grid-column: 1/3;
            grid-row: 1/5;
          }

          .story {
            grid-column: 3/4;
            grid-row: 3/5;
          }

          .stock {
            order: 7;
          }
        }
      }
    }

    .cont-feature {
      padding: 100px 0 100px 60px;

      .cont {
        &__area {
          overflow: hidden;
        }

        &__wrap {
          overflow: unset;

          &:hover {
            .swiper-button-prev, .swiper-button-next {
              &.#{$showClass} {
                .isNotTouchDevice & {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }

        &__item {
          width: 340px;
          margin-left: 20px;

          &:hover {
            .thumbnail {
              transform: scale(1.05);
            }
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 60px;
          }

          &.testbed {
            .thumbnail {
              background-image: url(/img/pc/main-feature-1784.png);
            }

            .text-box {
              background-color: #9facbe;
            }
          }

          &.arcverse {
            .thumbnail {
              background-image: url(/img/pc/main-feature-arcverse.png);
            }

            .text-box {
              background-color: $color-default;
            }
          }

          &.search {
            .thumbnail {
              background-image: url(/img/pc/main-feature-search.png);
            }

            .text-box {
              background-color: #0a52ff;
            }
          }

          &.cloud {
            .thumbnail {
              background-image: url(/img/pc/main-feature-cloud.png);
            }

            .text-box {
              background-color: #00fdff;
            }
          }

          &.datacenter {
            .thumbnail {
              background-image: url(/img/pc/main-feature-datacenter.png);
            }

            .text-box {
              background-color: #d8d4cc;
            }
          }

          &.fintech {
            .thumbnail {
              background-image: url(/img/pc/main-feature-fintech.png);
            }

            .text-box {
              background-color: #00e662;
            }
          }
        }

        &__btn {
          .swiper-button-prev, .swiper-button-next {
            visibility: hidden;
            width: 36px;
            height: 36px;
            margin-top: 0;
            background: url(/img/common/swiper-btn.png) no-repeat center;
            background-size: 100% auto;
            transition: all .3s;
            pointer-events: auto;
            opacity: 0;

            &::after {
              content: none;
            }

            &.swiper-button-disabled {
              background: url(/img/common/swiper-btn-disable.png) no-repeat center;
              background-size: 100% auto;
            }

            &.#{$showClass} {
              .isTouchDevice & {
                opacity: 1;
                visibility: visible;
              }
            }

            &:hover {
              background: url(/img/common/swiper-btn-hover.png) no-repeat center;
              background-size: 100% auto;
            }
          }

          .swiper-button-prev {
            left: 0;
            transform: translateY(-50%);
          }

          .swiper-button-next {
            right: 0;
            transform: rotate(180deg) translateY(50%);
          }
        }
      }

      .thumb-box {
        overflow: hidden;
        position: relative;

        .thumbnail {
          padding-top: 320px;
          background: no-repeat center;
          background-size: cover;
          transition: transform .3s;
        }
      }

      .text-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 320px;
        padding: 26px 20px 20px;
        background-color: $color-default;
        color: $color-white;
        font-weight: 700;
        text-transform: uppercase;

        &.color-black {
          color: $color-default;
        }

        .category {
          display: block;
          font-size: 14px;
        }

        .title {
          display: block;
          margin-top: 42px;
          font-size: 28px;
          line-height: 42px;
          letter-spacing: -.1px;
        }

        .desc {
          text-transform: none;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -.25px;
        }
      }
    }

    .cont-shortcut {
      padding: 60px 60px 0;

      .cont {
        &__item {
          position: relative;
          border-top: 1px solid var(--border01);

          &:first-child {
            border-top: 0;

            .cont__link {
              padding-top: 0;
            }
          }

          &:last-child {
            .cont__link {
              padding-bottom: 0;
            }
          }

          .title {
            position: relative;
            font-size: 32px;
            font-weight: 700;
            line-height: 52px;
            letter-spacing: -1.26px;
            transition: color .3s;
          }

          &:hover {
            .title {
              color: $color-point;

              &::after {
                display: block;
              }
            }

            .ico-link {
              &::after {
                background-image: url(/img/common/ic-quicklink-black.png);
                background-color: $color-point;
              }
            }

            .thumb-box {
              transition: none;
              opacity: 1;
              animation: thumbBoxEffect .3s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;

              img {
                animation: thumbnailEffect .3s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
              }
            }
          }
        }

        &__link {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 34px 20px;
        }
      }

      .ico-link {
        &::after {
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: var(--color-sub02) url(/img/common/ic-quicklink.png) no-repeat center;
          background-size: contain;
          transition: background .3s;
          content: '';
        }

        &.outlink {
          &::after {
            transform: rotate(-45deg);
          }
        }
      }

      .thumb-box {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 300px;
        height: 300px;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: opacity 0s linear .3s;
        pointer-events: none;

        img {
          transform: translate3d(100%, 0, 0);
        }
      }
    }
  }
}

.theme-dark {
  .main {
    .cont-summary {
      .slogan {
        .outlink::after {
          background: url(/img/common/ic-outlink-circle-white.png) no-repeat center;
          background-size: contain;
        }
      }
    }
  }
  .main-content__title {
    .btn-arrow {
      background-image: url(/img/common/ic-arrow-down.png);
    }
  }

  .cont-summary {
    .cont__item {
      background-color: $color-default;

      &.is-no-bg {
        .outlink::after {
          background: url(/img/common/ic-outlink-gray2.png) no-repeat center;
          background-size: contain;
        }
      }
    }

    .cont__link {
      color: $color-white;
    }

    .stock .num-info.up::after {
      background: var(--ic-arrow-primary) no-repeat center;
      background-size: contain;
    }

    .ir, .stock {
      .cont__link {
        color: $color-white;
      }
    }

    .news .text-box {
      background-color: $color-default;
    }
  }

  .cont-shortcut {
    .cont__item {
      border-color: #2f2e30;
    }

    .cont__link {
      color: $color-white;
    }

    .ico-link::after {
      background-color: #2f2e30;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.main {
      .main-content {
        &__inner {
          max-width: 1600px;
        }

        &__title {
          width: 287px;
        }
      }

      .cont-summary {
        padding: 130px 40px 60px;

        .cont__list {
          grid-template-columns: 49.068% 1fr 1fr;
          grid-template-rows: 505px 155px;
        }

        .cont__item {
          .head {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: normal;
          }

          .title {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -.3px;
          }

          .desc {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: normal;
          }
        }

        .media {
          .title {
            margin-top: 22px;
            font-size: 26px;
            line-height: 36px;
            letter-spacing: -.01px;
          }
        }

        .news {
          .thumb-box {
            height: 403px;
          }

          .title {
            font-size: 26px;
            line-height: 38px;
            letter-spacing: -.8px;
          }

          .date {
            font-size: 13px;
            line-height: 16px;
            letter-spacing: normal;
          }
        }

        .story {
          .category {
            font-size: 12px;
            line-height: normal;
          }

          .title {
            font-size: 22px;
            line-height: 30px;
            letter-spacing: -.5px;
          }
        }

        .stock {
          .num-info {
            font-size: 12px;
            line-height: normal;

            &::before {
              width: 16px;
              height: 16px;
            }

            &::after {
              width: 28px;
              height: 32px;
            }
          }

          .count {
            font-size: 34px;
            line-height: 42px;
            letter-spacing: normal;
          }
        }

        .ir {
          .title {
            margin-top: 10px;
          }
        }

        //type 스타일
        .cont__area {
          &.type-a {
            .news {
              .thumb-box {
                height: 350px;
              }

              .title {
                margin-top: 10px;
                font-size: 16px;
                line-height: 26px;
              }
            }
          }

          &.type-b {
            .cont__list {
              grid-template-rows: 330px 155px 155px;
            }
          }

          &.type-c {
            .cont__list {
              grid-template-rows: repeat(4, 155px);
            }
          }
        }
      }

      .cont-feature {
        padding: 100px 0 100px 40px;
        
        .cont__item {
          &:last-child {
            margin-right: 40px;
          }
        }
      }

      .cont-shortcut {
        padding: 60px 40px 0;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.main {
      .main-content {
        &__inner {
          max-width: none;
          flex-direction: column;
        }

        &__title {
          width: 100%;
          font-size: 36px;
          line-height: 48px;
          letter-spacing: -1.2px;

          &::after {
            display: none;
          }

          .time {
            margin-top: 16px;
          }
        }

        .btn-arrow {
          left: auto;
          right: 0;
          bottom: 8px;
          width: 32px;
          height: 36px;
        }
      }

      .cont__area {
        margin-top: 24px;
      }

      .cont-summary {
        padding: 92px 30px 0;

        .main-content {
          &__title {
            display: flex;
            align-items: flex-end;
            position: absolute;
            right: 30px;
            width: calc(50% - 38px);
            height: 112px;
            margin-top: 36px;
            font-size: 22px;
            line-height: 48px;
            letter-spacing: -1.2px;

            .time {
              width: 50%;
              margin-top: 0;
              margin-left: 8px;
            }
  
            .location {
              width: 50%;
            }
          }

        }

        .btn-arrow {
          display: none;
        }

        .slogan {
          &__link {
            height: 112px;
            margin-bottom: 24px;
          }
  
          .lottie {
            &__wrap {
              width: 284px;
              height: 112px;
            }

            &__container {
              height: 100%;
  
              &#sloganLottie2 {
                margin-top: 12px;
              }
            }
          }
  
          .outlink {
            padding-top: 69px;
          }
        }

        .cont__area {
          margin-top: 36px;
        }

        .cont__list {
          grid-gap: 16px;
          grid-template-columns: 49.171% 1fr 1fr;
          grid-template-rows: unset;
        }

        .cont__item {
          padding-top: 25.53%;

          .head {
            font-size: 10px;
            line-height: 16px;
            letter-spacing: -.01px;
          }

          .title {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -.5px;
          }

          .desc {
            font-size: 10px;
            line-height: 16px;
          }

          .outlink {
            right: 12px;
            bottom: 14px;
          }
        }

        .cont__link {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 12px 40px 12px 12px;
        }

        .media {
          .title {
            margin-top: 14px;
            font-size: 20px;
            line-height: 28px;
          }
        }

        .news {
          .thumb-box {
            height: 59.213%;
          }

          .text-box {
            padding: 12px 40px 12px 12px;
          }

          .title {
            font-size: 22px;
            line-height: 34px;
            letter-spacing: -.6px;
          }

          .date {
            font-size: 10px;
          }
        }

        .story {
          .category {
            font-size: 10px;
          }

          .title {
            font-size: 18px;
          }
        }

        .stock {
          .num-info {
            font-size: 10px;

            &::before {
              width: 14px;
              height: 14px;
            }

            &::after {
              right: 18px;
              bottom: 18px;
              width: 23px;
              height: 26px;
            }
          }

          .count {
            font-size: 26px;
            line-height: 38px;
            letter-spacing: -.8px;
          }
        }

        .ir {
          .title {
            margin-top: 2px;
          }
        }

        .cont__area {
          &.type-a {
            .media, .news {
              padding-top: 55.078%;
            }

            .news {
              .thumb-box {
                height: 69.194%;
              }

              .title {
                font-size: 14px;
                line-height: 24px;
                letter-spacing: -.3px;
              }
            }
          }

          &.type-b {
            .cont__list {
              grid-template-rows: unset;
            }

            .cont__item {
              padding-top: 52.84%;
            }

            .news {
              padding-top: unset;
            }

            .story {
              padding-top: 54.432%;
            }
          }

          &.type-c {
            .cont__list {
              grid-template-rows: unset;
            }

            .cont__item {
              padding-top: 52.84%;
            }

            .media, .news, .story {
              padding-top: unset;
            }
          }
        }
      }

      .cont-feature {
        padding: 120px 0;

        .main-content {
          &__title {
            padding: 0 30px;

            strong {
              br {
                display: none;
              }
            }
          }
        }

        .cont__area {
          margin-top: 40px;
          padding: 0 30px;
        }

        .cont__item {
          width: 300px;
          margin-left: 16px;
        }

        .cont__btn {
          .swiper-button-prev {
            left: -30px;
          }

          .swiper-button-next {
            right: -30px;
          }
        }

        .thumb-box {
          .thumbnail {
            padding-top: 282px;
          }
        }

        .text-box {
          min-height: 282px;
          padding: 20px 16px;

          .category {
            font-size: 12px;
          }

          .title {
            margin-top: 36px;
            font-size: 26px;
            line-height: 38px;
            letter-spacing: -.8px;
          }

          .desc {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }

      .cont-shortcut {
        padding: 0 30px;

        .cont__area {
          margin-top: 60px;
        }

        .cont__link {
          padding: 30px 0;
        }

        .cont__item {
          .title {
            font-size: 26px;
            line-height: 38px;
            letter-spacing: -.8px;
          }
        }

        .thumb-box {
          width: 240px;
          height: 240px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.main {
      .cont-summary {
        padding: 110px 30px 0;

        .main-content {
          &__title {
            width: calc(50% - 36px);
            height: 92px;
            margin-top: 4px;
            font-size: 20px;
            line-height: 1;
  
            .location {
              text-align: right;
            }
  
            .time {
              justify-content: flex-end;
              margin-left: 6px;
            }
          }
        }

        .slogan {
          &__link {
            height: 102px;
            margin-bottom: 28px;
          }
  
          .lottie {
            &__wrap {
              width: 259px;
              height: 102px;
            }

            &__container {
              &#sloganLottie1 {
                height: 38px;

                svg {
                  margin-left: -55px;
                }
              }
            }
          }
  
          .outlink {
            margin-left: 12px;
            padding-top: 62px;
          }
        }

        .cont__area {
          margin-top: 3px;
        }

        .cont__list {
          grid-gap: 12px;
          grid-template-columns: 1fr 1fr;
        }

        .cont__item {
          padding-top: 52.875%;

          .head {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: normal;
          }

          .title {
            font-size: 20px;
          }

          .desc {
            font-size: 12px;
            line-height: 18px;
          }

          .outlink {
            right: 16px;
            bottom: 16px;
          }
        }

        .cont__link {
          padding: 16px 40px 20px 16px;
        }

        .media {
          .title {
            margin-top: 25px;
            font-size: 31px;
            line-height: 43px;
          }
        }

        .news {
          .thumb-box {
            height: unset;
          }

          .text-box {
            padding: 20px 40px 24px 20px;
          }

          .title {
            font-size: 26px;
            line-height: 38px;
            letter-spacing: -.8px;
          }

          .date {
            font-size: 12px;
          }
        }

        .story {
          .category {
            font-size: 12px;
          }

          .title {
            margin-top: 16px;
            font-size: 26px;
            line-height: 38px;
          }
        }

        .stock {
          .num-info {
            font-size: 12px;

            &::before {
              width: 16px;
              height: 16px;
            }

            &::after {
              right: 20px;
              bottom: 20px;
              width: 28px;
              height: 32px;
            }
          }

          .count {
            font-size: 34px;
            line-height: 42px;
            letter-spacing: normal;
          }
        }

        .ir {
          .title {
            margin-top: 12px;
          }
        }


        .cont__area {
          &.type-a {
            .media {
              grid-column: 1/3;
              padding-top: 55.086%;
            }

            .news {
              grid-column: 1/2;
              grid-row: 2/5;
              padding-top: unset;

              .thumb-box {
                height: 68.056%;
              }

              .title {
                margin-top: 14px;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: -.25px;
              }

              .date {
                display: block;
              }
            }

            .recruit {
              grid-column: 2/3;
              grid-row: 2/3;
            }

            .stock {
              grid-column: 2/3;
              grid-row: 4/5;
            }

            .ir {
              grid-column: 2/3;
              grid-row: 3/4;
            }
          }

          &.type-b {
            .cont__list {
              grid-template-columns: 1fr 1fr;
            }

            .cont__item {
              padding-top: 52.875%;
            }

            .news {
              grid-column: 1/3;
              grid-row: 1/2;
              padding-top: 103.25%;

              .thumb-box {
                height: 64.434%;
              }
            }

            .story {
              grid-column: 1/3;
              grid-row: 2/3;
              padding-top: 54.52%;
            }
          }

          &.type-c {
            .cont__list {
              grid-template-columns: 1fr 1fr;
            }

            .cont__item {
              padding-top: 52.875%;
            }

            .media {
              grid-column: 1/2;
              grid-row: 2/3;
              padding-top: 112.645%;
            }

            .news {
              grid-column: 1/3;
              grid-row: 1/2;
              padding-top: 103.25%;

              .thumb-box {
                height: 64.434%;
              }
            }

            .story {
              grid-column: 2/3;
              grid-row: 2/3;
              padding-top: 112.645%;
            }

            .recruit {
              order: 6;
            }

            .ir {
              order: 5;
            }
          }
        }

      }

      .cont-feature {
        padding: 120px 0 120px;

        .cont__item {
          width: 320px;
          margin-left: 12px;
        }

        .cont__btn {
          display: none;
        }

        .thumb-box {
          .thumbnail {
            padding-top: 301px;
          }
        }

        .text-box {
          min-height: 301px;
          padding: 20px;

          .title {
            margin-top: 48px;
            font-size: 28px;
            line-height: 42px;
            letter-spacing: -.1px;
          }
        }
      }

      .cont-shortcut {
        .main-content__title {
          font-size: 30px;
          letter-spacing: -1px;
        }

        .cont__area {
          margin-top: 55px;
        }

        .cont__item {
          .title {
            font-size: 18px;
            line-height: normal;
            letter-spacing: -.5px;
          }

          &:hover {
            .thumb-box {
              display: none;
            }
          }
        }

        .cont__link {
          padding: 22px 0 25px;
        }

        .ico-link {
          &::after {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.main {
      .main-content {
        &__title {
          font-size: 34px;

          .time {
            margin-top: 14px;
          }
        }
      }

      &.lang-en {
        .cont-summary {
          .ad {
            .title {
              font-size: 20px;
            }
          }
        }
      }

      .cont-summary {
        padding: 110px 20px 0;

        .main-content {
          &__title {
            left: 20px;
            width: calc(100% - 40px);
            height: auto;
            margin-top: 146px;
            font-size: 18px;
            
  
            .location {
              text-align: left;
            }
  
            .time {
              justify-content: flex-start;
              margin: 0;
            }
          }
        }

        .slogan {
          &__link {
            height: 100%;
            margin-bottom: 84px;
          }
  
          .lottie {
            &__wrap {
              width: 243px;
              height: 96px;
            }

            &__container {
              &#sloganLottie1 {
                svg {
                  margin-left: -48px;
                }
              }

              &#sloganLottie2 {
                margin-top: 6px;
              }
            }
          }
  
          .outlink {
            margin-left: 12px;
            padding-top: 62px;

            &::after {
              width: 28px;
              height: 28px;
            }
          }
        }

        .cont__list {
          grid-gap: 12px 7px;
        }

        .cont__item {
          padding-top: 100%;

          .title {
            font-size: 16px;
          }

          .outlink {
            display: none;
            right: 14px;
            bottom: 14px;
          }
        }

        .cont__link {
          padding: 16px 40px 16px 16px;
        }

        .media {
          .title {
            margin-top: 22px;
            font-size: 30px;
            line-height: 42px;
          }
        }

        .news {
          .text-box {
            padding: 16px 40px 20px 16px;
          }

          .title {
            margin-top: 14px;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: -.25px;
          }

          .date {
            margin-top: 21px;
          }
        }

        .story {
          .title {
            font-size: 26px;
          }
        }

        .recruit {
          .title {
            font-size: 20px;
            font-weight: 700;
          }
        }

        .stock {
          .text-wrap {
            text-align: right;
          }

          .num-info {
            line-height: 26px;
            &::after {
              display: none;
            }
          }

          .count {
            font-size: 28px;
            line-height: 32px;
          }
        }

        .ir {
          .cont__link {
            justify-content: flex-start;
          }

          .title {
            margin-top: 10px;
          }

          .desc {
            margin-top: 10px;
          }
        }

        .cont__area {
          &.type-a {
            .media {
              padding-top: 149.256%;
            }

            .news {
              grid-column: 1/3;
              grid-row: 2/3;
              padding-top: 109vw;

              .thumb-box {
                height: 64.434vw;
              }
            }

            .recruit {
              grid-column: 1/3;
              grid-row: 3/4;
              padding-top: 52.84%;
            }

            .stock {
              grid-column: 2/3;
              grid-row: 4/5;
            }

            .ir {
              grid-column: 1/2;
              grid-row: 4/5;
            }
          }

          &.type-b {
            .cont__item {
              padding-top: 100%;
            }

            .news {
              grid-column: 1/3;
              grid-row: 1/2;
              padding-top: 109vw;

              .thumb-box {
                height: 64.434vw;
              }
            }

            .story {
              padding-top: 56.25%;
            }

            .ad {
              grid-column: 1/3;
              grid-row: 3/4;
              padding-top: 52.84%;
            }

            .recruit {
              order: 4;
              grid-column: 1/3;
              grid-row: 4/5;
              padding-top: 52.84%;
            }

            .ir {
              order: 5;
            }
          }

          &.type-c {

            .news {
              padding-top: 109vw;

              .thumb-box {
                height: 64.434vw;
              }
            }


            .media {
              grid-column: 1/3;
              padding-top: 112.54%;
            }

            .story {
              grid-column: 1/3;
              grid-row: 3/4;
              padding-top: 56.25%;
            }

            .ad {
              grid-column: 1/3;
              grid-row: 4/5;
              padding-top: 52.84%;
            }

            .recruit {
              order: 5;
              grid-column: 1/3;
              grid-row: 5/6;
              padding-top: 52.84%;
            }


            .ir {
              order: 6;
              padding-top: 100%;
            }
          }
        }
      }

      .cont-feature {
        padding: 120px 0;

        .main-content__title {
          padding: 0 20px;
          font-size: 30px;
          letter-spacing: -1px;
        }

        .cont__area {
          padding: 0 20px;
        }

        .thumb-box {
          .thumbnail {
            padding-top: 300px;
          }
        }

        .text-box {
          min-height: 300px;
        }
      }

      .cont-shortcut {
        padding: 0 20px;

        .cont__link {
          padding: 26px 4px 27px 0;
        }
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes thumbBoxEffect {
  0% {
    transform: translate3d(-150%, -50%, 0);
  }

  100% {
    transform: translate3d(-50%, -50%, 0);
  }
}

@keyframes thumbnailEffect {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
