.#{$component-name} {
  &.ir-governance {
    .container {
      padding-top: 0;
      padding-bottom: 40px;
    }

    .common {
      &__title {
        line-height: 1.38;

        @at-root .theme-dark & {
          border-bottom: 1px solid var(--border01);
        }

        &-inner {
          padding-top: 150px;
        }
      }
    }

    .ir-common {
      &__sub-title {
        max-width: 1800px;
        margin-bottom: 73px;
        padding-left: 460px;

        @at-root .theme-dark & {
          color: #6E6E73;
        }
      }

      &__button {
        padding: 0 15px 0 20px;
      }
    }

    .ir-table-number {
      border: 0;
    }

    .tab {
      &__area {
        display: none;
      }

      &__panels {
        max-width: none;
        padding: 0;
      }

      &__panel {
        display: block;
      }
    }

    .js-nav-section {
      padding: 45px 0 120px 460px;
    }

    .accordion {
      &__item {
        &.#{$activeClass} {
          .accordion {
            &__content {
              max-height: calc(1000vh);
            }
          }
        }
      }
    }

    .ir-governance-etc {
      .ir-common {
        &__sub-title {
          padding: 0;
        }
      }
    }

    .table {
      width: 100%;
      margin-top: 70px;
      border-top: 1px solid var(--border04);
      border-bottom: 1px solid var(--border01);
      font-size: 14px;
      color: #424245;
      line-height: 2.14;
      letter-spacing: -0.3px;
      text-align: left;

      .table-blind {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        font-size: 0;
        text-indent: -9999px;
        white-space: nowrap;
      }

      &.is-caption {
        margin-top: 0;
      }

      &.is-dropdown {
        margin-top: 30px;
      }

      &__caption {
        margin-top: 42px;
        margin-bottom: 12px;
        font-size: 14px;
        text-align: right;
        letter-spacing: -0.3px;

        @at-root .theme-dark & {
          color: #A1A1A6;
        }
      }

      &__thead {
        display: flex;

        &-th {
          flex: auto;
          padding: 20px 0;
          font-weight: 800;
          color: var(--default);
        }
      }

      &__tbody {
        &-th {
          padding: 20px 0;
          color: $color-default;
        }

        div {
          flex: auto;
          padding: 20px 0;
          color: var(--box-text);

          @at-root .theme-dark & {
            color: #fff;
          }

          &.#{bdClass} {
            font-weight: 700;
            color: $color-default;
          }
        }
      }

      &__tr {
        display: flex;
        align-items: center;
        border-top: 1px solid var(--border01);
      }

      &__info {
        margin-top: 30px;
        font-size: 12px;
        color: var(--accordionNum);
        text-align: right;
        letter-spacing: -0.26px;
      }
    }

    .ir-governance {
      &__contents {
        &-box {
          &.stockholder {
            .table {
              &__thead {
                &-th {
                  &:first-child {
                    width: 50%;
                  }

                  &:nth-child(2) {
                    width: 36.5%;
                  }

                  &:last-child {
                    width: 13.5%;
                  }
                }
              }

              &__tbody {
                div {
                  &:first-child {
                    width: 50%;
                  }

                  &:nth-child(2) {
                    width: 36.5%;
                  }

                  &:last-child {
                    width: 13.5%;
                  }

                  &.#{$bdClass} {
                    font-weight: 700;
                    color: var(--default);
                  }
                }

                &-th {
                  font-weight: 400;

                }
              }
            }
          }

          &.directors {
            .ir-common {
              &__button {
                margin-top: 40px;

                &:after {
                  width: 20px;
                  height: 20px;
                  background: url(../img/common/ic-download-underbar.png) no-repeat center;
                  background-size: contain;
                }
              }
            }

            .table {
              &__thead {
                &-th {
                  &:first-child {
                    width: 22%;
                  }

                  &:nth-child(2) {
                    width: 28%;
                  }

                  &:nth-child(3) {
                    width: 36.5%;
                  }

                  &:last-child {
                    width: 13.5%;
                  }
                }
              }

              &__tbody {
                div {
                  &:first-child {
                    width: 22%;
                  }

                  &:nth-child(2) {
                    width: 28%;
                  }

                  &:nth-child(3) {
                    width: 36.5%;
                  }

                  &:last-child {
                    width: 13.5%;
                  }
                }

                &-th {
                  color: var(--default);
                }

                &-td {
                  padding: 20px 0 60px;
                  line-height: 26px;

                  &.is-padding {
                    padding: 20px 10px 60px 0;
                  }
                }

                &.is-tab {
                  display: none;
                }
              }

              &__tr {
                align-items: start;
              }

              .#{$ebClass} {
                font-weight: 800;
              }
            }
          }

          &.committee {
            margin-top: 120px;

            .ir-common {
              &__button {
                height: 24px;
                padding: 0 0 0 24px;
                vertical-align: middle;

                &::before {
                  width: 24px;
                  height: 24px;
                }
              }
            }

            .table {
              &__thead {
                &-th {
                  &:first-child {
                    width: 27.5%;
                  }

                  &:nth-child(2) {
                    width: 22.5%;
                  }

                  &:nth-child(3) {
                    width: 27%;
                  }

                  &:last-child {
                    width: 23%;
                    text-align: center;
                  }
                }
              }

              &__tbody {
                div {
                  &:first-child {
                    width: 27.5%;
                  }

                  &:nth-child(2) {
                    width: 22.5%;
                  }

                  &:nth-child(3) {
                    width: 27%;
                  }

                  &:last-child {
                    width: 23%;
                    text-align: center;
                  }
                }

                &-th {
                  color: var(--default);
                }

                &-td {
                  &:last-child {
                    text-align: center;
                  }
                }
              }
            }
          }

          &.corporate {
            .section__title {
              font-size: 38px;
            }

            .ir-common {
              &__sub-title {

                margin: 40px 0 0 0;
              }

              &__button {
                margin-top: 60px;

                &:after {
                  width: 20px;
                  height: 20px;
                  background: url(../img/common/ic-download-underbar.png) no-repeat center;
                  background-size: contain;
                }
              }
            }
          }

          &.esg {
            margin-top: 120px;

            .table {
              text-align: center;

              &__thead {
                &-th {
                  &:first-child {
                    width: 10%;
                    text-align: left;
                  }

                  &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:last-child {
                    width: 17.9%;
                  }
                }
              }

              &__tbody {
                div {
                  &:first-child {
                    width: 10.5%;
                    text-align: left;
                  }

                  &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:last-child {
                    width: 17.9%;
                  }
                }

                &-th {
                  color: var(--default);
                }
              }
            }
          }

          &.standard {
            margin-top: 160px;

            .table {
              &__thead {
                &-th {
                  &:first-child {
                    width: 29%;
                  }

                  &:nth-child(2) {
                    width: 47%;
                    text-align: center;
                  }

                  &:last-child {
                    width: 24%;
                  }
                }
              }

              &__tbody {
                div {
                  &:first-child {
                    width: 31%;
                  }

                  &:nth-child(2) {
                    width: 42%;
                    text-align: center;
                  }

                  &:last-child {
                    width: 24%;
                    margin-left: 26px;
                  }
                }

                &-th {
                  font-weight: 400;
                }
              }

              .choose {
                &:after {
                  display: block;
                  width: 10px;
                  height: 10px;
                  margin: 0 auto;
                  border: 1px solid var(--storyDetailTxt);
                  border-radius: 50%;
                  content: '';
                }
              }
            }
          }
        }
      }

      &__disclaimer {
        margin-top: 70px;
        font-size: 14px;
        color: var(--accordionNum);
        letter-spacing: -0.3px;
      }

      &__accodian {
        margin-top: 20px;
      }

      &__info {
        border-top: 1px solid #e4e6ea;

        @at-root .theme-dark & {
          border-top: 1px solid #2f2e30;
        }

        &-box {
          display: block;
          position: relative;
          width: 100%;
          padding: 27px 92px 27px 0;
          // color: $color-default;
          text-align: left;
          transition: all .3s;

          &:after {
            position: absolute;
            top: 50%;
            right: 30px;
            width: 32px;
            height: 32px;
            background: var(--ic-accordion) no-repeat;
            background-size: 100%;
            transform: translateY(-50%);
            transition: all .3s;
            content: '';
          }

          &:hover {
            .ir-governance__info-title {
              color: $color-point;
            }

            & {
              color: $color-point;

              &::after {
                background: url(/img/common/ic-accordion-orange.png) no-repeat;
                background-size: 100%;
              }
            }
          }
        }

        &-title {
          display: block;
          font-weight: 800;
          font-size: 22px;
          line-height: 1.91;
          letter-spacing: -0.8px;
          color: var(--default);
        }

        &:last-child {
          border-bottom: 1px solid #2f2e30;
        }

        &.#{$activeClass} {
          .ir-governance__info-box {
            &:after {
              background-image: var(--ic-accordion-close);
            }

            &:hover {
              &:after {
                background-image: url(/img/common/ic-accordion-close-orange.png);
              }
            }
          }
        }
      }

      &__list {
        padding: 23px 0 90px;
      }

      &__item {
        margin-top: 30px;
        font-size: 14px;
        letter-spacing: -0.3px;
        line-height: 1.86;


        &:first-child {
          margin-top: 0;
        }

        &-title {
          font-weight: 800;
          color: var(--default);

          &.rule {
            margin-top: 20px;

            &:first-child {
              margin-top: 30px;
            }
          }
        }

        &.paragraph {
          margin: 60px 0 10px;

          &:first-child {
            margin: 0 0 10px;
          }

          .ir-governance__item-title {
            font-weight: 800;
            font-size: 18px;
            line-height: 1.44;
            letter-spacing: 3px;
          }
        }

        &-text {
          margin-top: 10px;
          color: var(--box-text);
        }
      }

      &__part {
        margin-top: 10px;

        span {
          display: block;
        }

        &-item {
          display: flex;
          margin-top: 5px;
          padding-left: 20px;
          color: var(--color-sub);

          &:first-child {
            margin-top: 0;
          }

          .num {
            margin-right: 14px;
          }

          &.sub-num {
            margin-top: 5px;
            padding-left: 57px;

            .num {
              margin-right: 5px;
            }
          }

          &.sub-text {
            margin-top: 5px;
            padding-left: 75px;

            .num {
              margin-right: 3px;
            }
          }
        }
      }
    }

    .ir-schedule__head {
      justify-content: space-between;
    }

    .type-eng {
      display: none !important;
    }

    @at-root &.lang-en {
      .type {
        &-kor {
          display: none !important;
        }

        &-eng {
          display: block !important;
        }
      }
    }
  }
}

//$screen-lg: 1600 - 1919px;
@include breakpoint(pc) {
  .#{$component-name} {
    &.ir-governance {
      .br_pc {
        display: block;
      }

      .ir-common {
        &__sub-title {
          max-width: 1520px;
          padding-left: 386px;
        }
      }

      .js-nav-section {
        padding-left: 386px;
      }

      .ir-governance {
        &__contents {
          &-box {
            &.stockholder {
              .table {
                &__thead {
                  &-th {
                    &:first-child {
                      width: 49%;
                    }

                    &:nth-child(2) {
                      width: 37.5%;
                    }
                  }
                }

                &__tbody {
                  div {
                    &:first-child {
                      width: 49%;
                    }

                    &:nth-child(2) {
                      width: 37.5%;
                    }
                  }
                }
              }
            }

            &.directors {
              .table {
                &__thead {
                  &-th {
                    &:nth-child(2) {
                      width: 27%;
                    }

                    &:nth-child(3) {
                      width: 37.5%;
                    }
                  }
                }

                &__tbody {
                  div {
                    &:nth-child(2) {
                      width: 27%;
                    }

                    &:nth-child(3) {
                      width: 37.5%;
                    }
                  }
                }
              }
            }

            &.committee {
              .table {
                &__thead {
                  &-th {
                    &:first-child {
                      width: 31.5%;
                    }

                    &:nth-child(2) {
                      width: 26%;
                    }

                    &:nth-child(3) {
                      width: 20.5%;
                    }

                    &:last-child {
                      width: 22%;
                    }
                  }
                }

                &__tbody {
                  div {
                    &:first-child {
                      width: 31.5%;
                    }

                    &:nth-child(2) {
                      width: 26%;
                    }

                    &:nth-child(3) {
                      width: 20.5%;
                    }

                    &:last-child {
                      width: 22%;
                    }
                  }
                }
              }
            }

            &.standard {
              .table {
                &__thead {
                  &-th {
                    &:first-child {
                      width: 37.5%;
                    }

                    &:nth-child(2) {
                      width: 36.5%;
                    }

                    &:last-child {
                      width: 26%;
                    }
                  }
                }

                &__tbody {
                  div {
                    &:first-child {
                      width: 37.5%;
                    }

                    &:nth-child(2) {
                      width: 32.5%;
                    }

                    &:last-child {
                      width: 24%;
                      margin-left: 13px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//$screen-md: 1024 - 1599px;
@include breakpoint(tablet) {
  .#{$component-name} {
    &.ir-governance {

      .common {
        &__title {
          &-inner {
            width: auto;
            padding-top: 140px;
          }
        }
      }

      .ir-common {
        &__sub-title {
          max-width: none;
          margin: 0;
          padding: 60px 30px 0;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
        }
      }

      .js-nav-section {
        padding: 120px 30px 0;
      }

      .table {
        margin-top: 60px;

        &__caption {
          margin-top: 32px;
        }
      }

      .ir-governance-etc {
        .ir-common {
          &__sub-title {
            margin: 0;
            padding: 0;
          }
        }
      }

      .ir-governance {
        &__contents {
          &-box {
            &.stockholder {
              .table {
                &__thead {
                  &-th {
                    &:first-child {
                      width: 48%;
                    }

                    &:nth-child(2) {
                      width: 38.5%;
                    }
                  }
                }

                &__tbody {
                  div {
                    &:first-child {
                      width: 48%;
                    }

                    &:nth-child(2) {
                      width: 38.5%;
                    }
                  }
                }
              }
            }

            &.directors {
              .table {
                &__thead {
                  &-th {
                    &:first-child {
                      width: 20%;
                    }

                    &:nth-child(2) {
                      width: 25.5%;
                    }

                    &:nth-child(3) {
                      width: 41%;
                    }

                    &:last-child {
                      width: 13.5%;
                    }
                  }
                }

                &__tbody {
                  div {
                    &:first-child {
                      width: 20%;
                    }

                    &:nth-child(2) {
                      width: 25.5%;
                    }

                    &:nth-child(3) {
                      width: 41%;
                    }

                    &:last-child {
                      width: 13.5%;
                    }
                  }
                }
              }
            }

            &.committee {
              .table {
                &__thead {
                  &-th {
                    &:first-child {
                      width: 32%;
                    }

                    &:nth-child(2) {
                      width: 23.5%;
                    }

                    &:nth-child(3) {
                      width: 22.5%;
                    }

                    &:last-child {
                      width: 22%;
                    }
                  }
                }

                &__tbody {
                  div {
                    &:first-child {
                      width: 32%;
                    }

                    &:nth-child(2) {
                      width: 23.5%;
                    }

                    &:nth-child(3) {
                      width: 22.5%;
                    }

                    &:last-child {
                      width: 22%;
                    }
                  }
                }
              }
            }

            &.esg {
              .table {
                &__thead {
                  &-th {
                    line-height: 2.29;

                    &:first-child {
                      width: 13%;
                    }
                  }
                }

                &__tbody {
                  div {
                    line-height: 2.29;

                    &:first-child {
                      width: 13%;
                    }
                  }
                }
              }
            }

            &.standard {
              margin-top: 120px;

              .table {
                &__thead {
                  &-th {
                    &:first-child {
                      width: 39%;
                    }

                    &:nth-child(2) {
                      width: 32%;
                    }

                    &:last-child {
                      width: 29%;
                    }
                  }
                }

                &__tbody {
                  div {
                    &:first-child {
                      width: 44%;
                    }

                    &:nth-child(2) {
                      width: 25%;
                    }

                    &:last-child {
                      width: 30%;
                      margin-left: 35px;
                    }
                  }
                }
              }
            }
          }
        }

        &__disclaimer {
          margin-top: 60px;
        }
      }
    }
  }
}

//$screen-xmd: 768 - 1023px;
@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.ir-governance {

      .ir-common {
        &__sub-title {
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
        }
      }

      .table {
        margin-top: 50px;

        &__caption {
          margin-top: 27px;
          margin-bottom: 10px;
          font-size: 12px;
          letter-spacing: -0.26px;
        }

        &__thead {
          &-th {
            padding: 14px 0;
          }
        }

        &__tbody {
          div {
            padding: 14px 0;
          }
        }
      }

      .ir-governance {
        &__contents {
          &-box {
            &.stockholder {
              .table {
                &__thead {
                  &-th {
                    &:first-child {
                      width: 51%;
                    }

                    &:nth-child(2) {
                      width: 31%;
                    }

                    &:last-child {
                      width: 19%;
                    }
                  }
                }

                &__tbody {
                  div {
                    &:first-child {
                      width: 51%;
                    }

                    &:nth-child(2) {
                      width: 31%;
                    }

                    &:last-child {
                      width: 19%;
                    }
                  }
                }
              }
            }

            &.directors {
              .ir-common__button {
                margin-top: 30px;
              }

              .table {
                &__thead {
                  display: none;
                }

                &__tbody {
                  div {
                    &:first-child {
                      width: 50%;
                    }

                    &:nth-child(2) {
                      width: 100%;
                    }

                    &:nth-child(3) {
                      width: 100%;
                      padding-top: 30px;
                    }

                    &:last-child {
                      width: 50%;
                    }
                  }

                  .table-blind {
                    display: block;
                    overflow: auto;
                    position: static;
                    width: auto;
                    height: auto;
                    margin-bottom: 6px;
                    font-size: 14px;
                    text-indent: 0;
                    color: #a1a1a6;
                    letter-spacing: -0.3px;
                    line-height: 1.86;

                    @at-root .theme-dark & {
                      color: var(--secDesc);
                    }
                  }

                  &-th {
                    padding: 10px 0 0 0;
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 1.89;
                    letter-spacing: -0.5px;
                  }

                  &-td {
                    padding: 20px 0 0;

                    &.mo-profile {
                      padding-top: 67px;
                    }
                  }

                  &.is-tab {
                    display: block;
                  }

                  &.is-narmal {
                    display: none;
                  }
                }

                &__tr {
                  position: relative;
                  padding-bottom: 40px;
                }

                &__mo-box {
                  width: 50%;
                  padding: 0;

                  div {
                    &:last-child {
                      width: 100%;
                    }
                  }
                }
              }
            }

            &.committee {
              margin-top: 100px;

              .table {
                &__thead {
                  &-th {
                    &:first-child {
                      width: 35.5%;
                    }

                    &:nth-child(2) {
                      width: 21%;
                    }

                    &:nth-child(3) {
                      width: 28.5%;
                    }

                    &:last-child {
                      width: 15%;
                    }
                  }
                }

                &__tbody {
                  div {
                    &:first-child {
                      width: 35.5%;
                    }

                    &:nth-child(2) {
                      width: 21%;
                    }

                    &:nth-child(3) {
                      width: 28.5%;
                    }

                    &:last-child {
                      width: 15%;
                    }
                  }
                }
              }
            }

            &.corporate {
              .ir-common__button {
                margin-top: 30px;
              }

              .section__title {
                font-size: 26px;
              }
            }

            &.esg {
              margin-top: 100px;

              .table {
                &__thead {
                  &-th {
                    line-height: 1.86;

                    &:first-child {
                      width: 16%;
                    }
                  }
                }

                &__tbody {
                  div {
                    line-height: 1.86;

                    &:first-child {
                      width: 16%;
                    }
                  }
                }
              }
            }

            &.standard {
              margin-top: 100px;

              .table {
                width: auto;

                &__thead {
                  &-th {
                    &:first-child {
                      width: 25%;
                    }

                    &:nth-child(2) {
                      width: 46%;
                    }

                    &:last-child {
                      width: 29%;
                    }
                  }
                }

                &__tbody {
                  div {
                    line-height: 1.71;

                    &:first-child {
                      width: 32%;
                    }

                    &:nth-child(2) {
                      width: 23%;
                    }

                    &:last-child {
                      width: 26%;
                      margin-left: 71px;
                    }
                  }
                }
              }
            }
          }
        }

        &__disclaimer {
          margin-top: 50px;
          font-size: 12px;
          letter-spacing: -0.26px;
        }

        &__info {
          &-title {
            font-size: 18px;
            letter-spacing: -0.5px;
            line-height: 1.89;
          }

          &-box {
            padding: 20px 58px 20px 0;

            &:after {
              right: 20px;
              width: 18px;
              height: 18px;
            }
          }
        }

        &__list {
          padding: 20px 0 70px;
        }

        &__item {
          margin-top: 23px;

          &-title, &-text {
            font-size: 12px;
            line-height: 22px;
            letter-spacing: -0.26px;
          }
        }

        &__part {
          font-size: 12px;
          letter-spacing: -0.5px;
          line-height: 1.89;
        }
      }
    }

    .ir-table {
      tbody {
        tr {
          &:first-child {
            @at-root .theme-dark & {
              border-top: 1px solid #6E6E73;
            }
          }
        }
      }
    }
  }
}

//$screen-sm: 375 - 767px;
@include breakpoint(mobile) {
  .#{$component-name} {
    &.ir-governance {
      .br_pc {
        display: none;
      }



      .common {
        &__title {

          @at-root .theme-dark & {
            border: none;
          }

          &-inner {
            padding-top: 112px;
            padding-bottom: 41px;
          }
        }
      }

      .ir-common {
        &-tab {
          display: block;
        }

        &__sub-title {
          padding: 40px 20px 0;
          font-size: 16px;
          line-height: 1.875;
        }

        &__button {
          &.see-all {
            margin-top: 30px;
          }
        }
      }

      .tab {
        &__area {
          display: block;
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;
          /* Firefox */

          &::-webkit-scrollbar {
            display: none;
          }
        }

        &__list {
          display: inline-block;
          overflow: visible;
        }

        &__panel {
          display: none;
        }
      }

      .anchor-nav {
        display: none;
      }

      .js-nav-section {
        margin-top: 60px;
        padding: 0 20px;
      }

      .table {
        .table-blind {
          overflow: auto;
          position: static;
          width: auto;
          height: auto;
          font-size: 14px;
          text-indent: 0;
          color: #a1a1a6;
          letter-spacing: -0.3px;
          line-height: 1.86;

          @at-root .theme-dark & {
            color: var(--secDesc);
          }
        }

        &__caption {
          margin-top: 14px;
          margin-bottom: 8px;
          font-size: 10px;
          line-height: 1.8;
          letter-spacing: normal;
        }

        &__thead {
          display: none;
        }

        &__tbody {
          div {
            .table-blind {
              font-weight: 400;
            }

            &.#{$bdClass} {
              font-weight: 800;
            }
          }
        }

        &__tr {
          flex-direction: column;
        }

        &__info {
          margin-top: 10px;
          font-size: 10px;
          line-height: 1.8;
          text-align: left;
        }
      }

      .ir-governance {
        &__contents {
          display: none;

          &.#{$activeClass} {
            display: block;
          }

          &.ir-governance-etc {
            padding: 0;
          }

          &-box {
            &.stockholder {
              .table {
                .table-blind {
                  margin-right: 14px;
                }

                &__tbody {
                  div {
                    padding: 0;

                    &:first-child, &:nth-child(2), &:last-child {
                      width: 100%;
                    }

                    &:last-child {
                      margin-top: 0;
                    }
                  }

                  &-th {
                    padding: 0;
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 1.89;
                    letter-spacing: -0.5px;
                  }

                  &-td {
                    margin-top: 20px;
                  }
                }

                &__tr {
                  padding: 10px 0 20px;

                  &:first-child {
                    border-top: none;
                  }

                  &.total {
                    border-color: var(--default);
                  }
                }
              }
            }

            &.directors {
              .table {
                margin-top: 40px;

                &__tbody {
                  div {
                    &:first-child, &:nth-child(2), &:nth-child(3), &:last-child {
                      width: 100%;
                    }

                    &:nth-child(3) {
                      padding-top: 20px;
                    }
                  }

                  .table-blind {
                    display: block;
                  }

                  &-th {
                    padding: 10px 0 0 0;
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 1.89;
                    letter-spacing: -0.5px;
                  }

                  &-td {
                    padding: 20px 0 0;

                    &:last-child {
                      padding-bottom: 30px;

                      .table-blind {
                        margin-bottom: 6px;
                      }
                    }
                  }

                  &.is-tab {
                    display: none;
                  }

                  &.is-narmal {
                    display: block;
                  }
                }

                &__tr {
                  padding-bottom: 0;
                }
              }
            }

            &.committee {
              margin-top: 100px;

              .table {
                margin-top: 40px;

                &__tbody {
                  div {
                    &:first-child, &:nth-child(2), &:nth-child(3), &:last-child {
                      width: 100%;
                      text-align: left;
                    }
                  }

                  &-th {
                    padding: 10px 0 20px 0;
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 1.89;
                    letter-spacing: -0.5px;
                  }

                  &-td {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    color: var(--color-sub);

                    &:first-child {
                      margin-top: 20px;
                    }

                    &:last-child {
                      margin-top: 14px;
                      padding: 0;

                      .table-blind {
                        width: 200px;
                        font-weight: 700;
                        color: $color-default;

                        @at-root .theme-dark & {
                          color: var(--default);
                        }
                      }
                    }
                  }

                  .ir-common__button {
                    height: 20px;
                    margin-right: 6px;

                    &::before {
                      width: 20px;
                      height: 20px;
                    }
                  }

                  .table-blind {
                    display: block;
                    width: 39px;
                    margin-right: 14px;
                  }
                }

                &__tr {
                  padding-bottom: 30px;
                }
              }
            }

            &.corporate {
              .ir-common {
                &__sub-title {
                  display: block;
                  width: 100%;
                  padding: 0 20px;
                  font-size: 16px;
                  line-height: 1.88;
                  letter-spacing: -0.5px;
                }

                &__button {
                  &.see-all {
                    margin-left: 20px;
                  }
                }
              }

              .section__title {
                padding: 0 20px;
                font-size: 26px;
              }
            }

            &.esg {
              margin-top: 100px;
              padding: 0 20px;

              .table {
                &__thead {
                  display: flex;

                  &-th {
                    padding: 14px 0;
                    font-size: 12px;

                    &:first-child {
                      width: 5%;
                      font-size: 14px;
                    }

                    &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:last-child {
                      flex: 1;
                      align-self: end;
                      width: auto;
                    }
                  }
                }

                &__tbody {
                  div {
                    padding: 14px 0;

                    &:first-child {
                      width: 5%;
                    }

                    &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:last-child {
                      flex: 1;
                      align-self: end;
                      width: auto;
                    }
                  }

                  &-th {
                    font-size: 12px;
                    line-height: 26px;
                    letter-spacing: -0.5px;
                  }

                  &-td {
                    font-size: 12px;
                  }
                }

                &__tr {
                  flex-direction: row;
                }
              }

            }

            &.standard {
              margin-left: 20px;
              border-bottom: 1px solid var(--border01);

              .table {
                width: 590px;
                margin-top: 40px;
                border-bottom: none;

                &__wrap {
                  overflow-x: scroll;

                  &::-webkit-scrollbar {
                    height: 4px;
                    margin-top: -2px;
                  }

                  &::-webkit-scrollbar-thumb {
                    border-radius: 2px;
                    background-color: #cececf;
                  }

                  @at-root .theme-dark & {
                    &::-webkit-scrollbar-thumb {
                      background-color: #1d1d1f;
                    }
                  }
                }

                &__thead {
                  display: flex;
                }

                &__tbody {
                  div {
                    line-height: 2.14;

                    &:first-child {
                      width: 30%;
                    }

                    &:nth-child(2) {
                      width: 14%;
                    }

                    &:last-child {
                      width: 21%;
                      margin-left: 80px;
                    }
                  }
                }

                &__tr {
                  flex-direction: row;
                }
              }
            }
          }
        }

        &__disclaimer {
          margin-top: 14px;
          font-size: 10px;
          letter-spacing: normal;
          line-height: 1.8;
        }

        &__accodian {
          margin-top: 8px;
        }

        &__info {
          &.#{$activeClass} {
            .#{$component-name}__list {
              border-top: 1px solid #e4e6ea;
            }
          }

          &:first-child {
            border-top: 1px solid $color-default;
          }

          &-box {
            padding: 16px 38px 16px 0;

            &:after {
              right: 10px;
            }
          }

          &-title {
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }

          &-btn {
            &-img {
              width: 18px;
              height: 18px;
            }
          }
        }

        &__list {
          padding: 16px 0 50px;
        }

        &__item {
          margin-top: 30px;

          &-title, &-text {
            font-size: 12px;
            line-height: 1.83;
            letter-spacing: -0.26px;
          }

          &.paragraph {
            margin: 40px 0 -10px;

            &:first-child {
              margin: 8px 0 -10px;
            }

            .ir-governance__item-title {
              font-size: 16px;
              line-height: 1.63;
              letter-spacing: 2px;
            }
          }
        }

        &__part {
          &-item {
            padding-left: 5px;
            font-size: 12px;
            line-height: 1.83;
            letter-spacing: -0.26px;

            .num {
              margin-right: 12px;
            }

            &.sub-num {
              padding-left: 35px;
            }

            &.sub-text {
              padding-left: 51px;
            }
          }
        }
      }
    }

    &.ir-schedule {
      .ir-table {
        thead {
          display: block;

          tr {
            @at-root .theme-dark & {
              border-top: 1px solid #6E6E73;
            }
          }
        }
      }
    }
  }
}
