.#{$component-name} {
  &.map {
    .section__visual {
      background-image: url('https://static-homepage.pstatic.net/cdn-static/navercorp/img/service/service-map-main.jpg');

      &::before {
        content: '';
        width: 100%;
        height: 100vh;
        display: block;
      }
    }

    .service__bg {
      height: 87.963%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .js-nav-section {
      &.naver-map {
        .service__bg {
          background-image: url('https://static-homepage.pstatic.net/cdn-static/navercorp/img/service/service-map-bg.png');
        }
      }

      &.place {
        .service__img-wrap {
          height: 1006px;
        }

        .service__img-box {
          position: absolute;
          top: 0;
          left: 50%;
          min-width: 1340px;
          height: 100%;
          transform: translateX(-50%);
        }

        .service__img {
          position: absolute;
          bottom: 0;
          width: 380px;
          height: 801px;

          img {
            position: relative;
            top: 1px;
          }

          &:first-child {
            left: 0;
          }

          &:nth-child(2) {
            top: 0;
            bottom: auto;
            left: 50%;
            transform: translateX(-50%);
          }

          &:last-child {
            right: 0;
          }
        }

        .service__bg {
          top: 153px;
          height: 69.186%;
          background-color: #0ce47d;
          transform: translate(-50%, 0);
        }
      }
    }

    .map {
      &__list {
        max-width: 1118px;
        margin: 100px auto 0;
        border-top: 1px solid var(--border01);
        text-align: left;

        &-head {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding-top: 80px;

          .num {
            font-weight: 700;
            font-size: 16px;
            color: var(--default);
          }

          .title {
            margin-top: 20px;
            font-weight: 800;
            font-size: 32px;
            width: fit-content;
            line-height: 1.5;
            letter-spacing: -1px;
            color: var(--default);
          }

          .text {
            position: relative;
            top: 31px;
            width: 549px;
            font-weight: 700;
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
            color: var(--color-sub);
          }
        }
      }

      &__accordion {
        position: relative;
        max-width: 1118px;
        min-height: 692px;
        margin: 80px auto 0;

        .img-box {
          visibility: hidden;
          position: absolute;
          top: 0;
          right: 0;
          width: 549px;
          background-color: var(--privacyBg);
          transition: all 1s;
          opacity: 0;
        }

        &-list {
          width: 460px;
        }

        &-item {
          padding: 20px 0;
          border-top: 1px solid var(--border01);

          &:last-child {
            border-bottom: 1px solid var(--border01);
          }

          &.is-active {
            .accordion__content {
              max-height: none;
              height: 100%;
            }

            .map__accordion-button {
              &:after {
                background-image: var(--ic-accordion-close);
              }

              &:hover {
                &:after {
                  background-image: url(/img/common/ic-accordion-close-orange.png);
                }
              }
            }

            .img-box {
              opacity: 1;
              visibility: visible;
            }
          }

          &:only-child {
            padding: 0;
            border: none;

            .map__accordion-text {
              margin-top: 0;
            }
          }
        }

        &-button {
          position: relative;
          width: 100%;
          padding-right: 30px;
          text-align: left;

          &:after {
            position: absolute;
            top: 50%;
            right: 10px;
            width: 18px;
            height: 18px;
            background-image: var(--ic-accordion);
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateY(-50%);
            transition: all .3s;
            content: '';
          }

          &:hover {
            &:after {
              background-image: url(/img/common/ic-accordion-orange.png);
            }

            .map__accordion-title {
              color: $color-point;
            }
          }
        }

        &-title {
          font-weight: 800;
          font-size: 22px;
          line-height: 1.91;
          letter-spacing: -0.6px;
          transition: all .3s;
        }

        &-text {
          margin-top: 20px;
          padding-right: 30px;
          font-size: 18px;
          line-height: 1.89;
          letter-spacing: -0.5px;
          text-align: left;
          color: var(--color-sub);

          .sub-text {
            display: block;
            margin-top: 23px;
          }
        }
      }

      &__item {
        display: flex;
        justify-content: space-between;
        margin: 80px auto 0;

        &-text {
          width: 400px;

          .tit {
            font-weight: 800;
            font-size: 22px;
            line-height: 1.91;
            letter-spacing: -0.6px;
          }

          .txt {
            margin-top: 15px;
            line-height: 30px;
            letter-spacing: -0.5px;
            color: #6e6e73;
          }
        }

        &-image {
          width: 549px;
          margin-top: 40px;
        }
      }
    }

    .small-business {
      .service__text {
        font-size: 22px;
        line-height: 1.9;
        letter-spacing: -0.6px;
      }

      &__card {
        max-width: 1345px;
        margin: 100px auto 0;
        padding-top: 66px;
        overflow: hidden;
        border-top: 1px solid #E4E6EA;

        &-list {
          font-size: 0;
          margin: -80px 0 0 -20px;
        }

        &-item {
          display: inline-block;
          width: calc((100% - 60px) / 3);
          margin: 80px 0 0 20px;

          &:nth-child(2) .small-business__card-img {
            background-color: #C9ECDF;

            &::after {
              background-image: url('https://static-homepage.pstatic.net/cdn-static/navercorp/img/service/service-map-small-place-img-2.png');
            }
          }

          &:nth-child(3) .small-business__card-img {
            background-color: #D1E2EF;

            &::after {
              background-image: url('https://static-homepage.pstatic.net/cdn-static/navercorp/img/service/service-map-small-place-img-3.png');
            }
          }

          &:nth-child(4) .small-business__card-img {
            background-color: #97CD6C;

            &::after {
              background-image: url('https://static-homepage.pstatic.net/cdn-static/navercorp/img/service/service-map-small-place-img-4.png');
            }
          }

          &:nth-child(5) .small-business__card-img {
            background-color: #95D1C2;

            &::after {
              background-image: url('https://static-homepage.pstatic.net/cdn-static/navercorp/img/service/service-map-small-place-img-5.png');
            }
          }

          &:nth-child(6) .small-business__card-img {
            background-color: #A8D9E0;

            &::after {
              background-image: url('https://static-homepage.pstatic.net/cdn-static/navercorp/img/service/service-map-small-place-img-6.png');
            }
          }
        }

        &-link {
          display: block;
        }

        &-img {
          position: relative;
          height: 290px;
          background-color: #B9E6BD;

          &::after {
            display: block;
            position: absolute;
            content: "";
            top: 50%;
            left: 50%;
            width: 100%;
            height: 240px;
            transform: translate(-50%, -50%);
            background: url('https://static-homepage.pstatic.net/cdn-static/navercorp/img/service/service-map-small-place-img-1.png') no-repeat center center;
            background-size: contain;
          }
        }

        &-link {
          &:hover {
            .small-business__card-title {
              color: #ff5f00;

              &::after {
                background: url(/img/common/btn-outlink-on.png) no-repeat center;
                background-size: 100% auto;
              }
            }
          }
        }

        &-textbox {
          margin-top: 20px;
          min-height: 110px;
          text-align: left;
        }

        &-title {
          font-weight: 800;
          font-size: 22px;
          line-height: 1.5;
          letter-spacing: -0.6px;
          color: #1D1D1F;
          transition: color .3s;

          &::after {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin-left: 4px;
            background: var(--btn-outlink) no-repeat center;
            background-size: contain;
            vertical-align: middle;
            transition: background .3s;
            content: '';
          }
        }

        &-desc {
          margin-top: 16px;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.8;
          letter-spacing: -0.5px;
          color: #6E6E73;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.map {
      .js-nav-section {
        &.place {
          .service__img-wrap {
            height: 832px;
          }

          .service__img-box {
            min-width: 1128px;
          }

          .service__bg {
            top: 128px;
            height: 69.712%;
          }

          .service__img {
            width: 316px;
            height: 666px;
          }
        }
      }

      .map {
        &__list {
          max-width: 944px;

          &-head {
            padding-top: 62px;
          }

          .text {
            position: relative;
            top: 31px;
            width: 460px;
          }
        }

        &__accordion {
          max-width: 944px;
          min-height: 580px;

          &-list {
            width: 420px;
          }

          .img-box {
            width: 460px;
          }
        }

        &__item {
          &-text {
            width: 420px;
          }

          &-image {
            width: 460px;
          }
        }
      }

      .small-business {
        &__card {
          max-width: 1135px;
          padding-top: 60px;

          &-list {
            margin: -60px 0 0 -20px;
          }

          &-item {
            margin: 60px 0 0 20px;
          }

          &-img {
            height: 243px;

            &::after {
              height: 158px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.map {
      .service__bg {
        height: 78.999%;
      }

      .js-nav-section {
        &.place {
          .service__img-wrap {
            height: 698px;
          }

          .service__text {
            max-width: 840px;
            margin: 120px auto;
          }

          .service__img-box {
            min-width: 836px;
          }

          .service__bg {
            top: 138px;
            height: 60.173%;
          }

          .service__img {
            width: 252px;
            height: 531px;
          }
        }
      }

      .map {
        &__list {
          max-width: 800px;
          margin: 80px auto 0px;

          &-head {
            padding-top: 50px;
          }

          .title {
            margin-top: 16px;
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
          }

          .text {
            position: relative;
            top: 25px;
            width: 385px;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
          }
        }

        &__accordion {
          max-width: 800px;
          min-height: 492px;

          .img-box {
            width: 390px;
          }

          &-list {
            width: 328px;
          }

          &-item {
            padding: 18px 0;
          }

          &-title {
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }

          &-text {
            margin-top: 12px;
            padding-bottom: 6px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__item {
          &-text {
            width: 328px;
          }

          &-image {
            width: 390px;
          }
        }
      }

      .small-business {
        .service__text {
          margin-top: 80px;
          font-size: 18px;
          line-height: 1.89;
          letter-spacing: -0.5px;
        }

        &__card {
          max-width: none;
          margin: 80px 30px 0;

          &-img {
            height: 205px;

            &::after {
              height: 133px;
            }
          }

          &-title {
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }

          &-desc {
            margin-top: 10px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.map {
      .service__bg {
        height: 83.898%;
      }

      .js-nav-section {
        .service__text {
          max-width: 680px;
          margin: 60px auto;
        }

        &.place {
          .service__img-wrap {
            height: 518px;
          }

          .service__text {
            max-width: 710px;
            margin: 60px auto;
          }

          .service__img-box {
            min-width: 622px;
          }

          .service__bg {
            top: 91px;
            height: 64.674%;
          }

          .service__img {
            width: 190px;
            height: 400px;
          }
        }
      }

      .map {
        &__list {
          max-width: 720px;
          margin: 60px auto 0;

          .title {
            margin-top: 10px;
          }

          .text {
            width: 354px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__accordion {
          max-width: 708px;
          min-height: 439px;
          margin: 60px auto 0;

          .img-box {
            width: 348px;
          }

          &-list {
            width: 310px;
          }
        }

        &__item {
          &-text {
            width: 310px;
          }

          &-image {
            width: 348px;
          }
        }
      }

      .small-business {
        .service__text {
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.5px;
        }

        &__card {
          padding-top: 50px;

          &-list {
            margin: -50px 0 0 -12px;
          }

          &-item {
            width: calc((100% - 24px) / 2);
            margin: 50px 0 0 12px;
          }

          &-img {
            height: 232px;

            &::after {
              height: 151px;
            }
          }

          &-textbox {
            margin-top: 16px;
          }

          &-title {
            line-height: 1.89;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.map {
      .section__visual {
        background-image: url('https://static-homepage.pstatic.net/cdn-static/navercorp/img/service/mo/service-map-main.jpg');
      }

      .map {
        &__list {
          .text {
            max-width: 330px;
          }
        }
      }

      .service__bg {
        height: 84.083%;
      }

      .js-nav-section {
        &.naver-map {
          .service__bg {
            background-image: url('https://static-homepage.pstatic.net/cdn-static/navercorp/img/service/mo/service-map-bg.png');
          }
        }

        &.place {
          .service__img-wrap {
            height: 527px;
          }

          .service__img-box {
            max-width: 622px;
            min-width: 375px;
          }

          .service__bg {
            top: 86px;
            height: 67.365%;
          }

          .service__img {
            width: 200px;
            height: 421px;

            &:first-child {
              left: -132px;
            }

            &:last-child {
              right: -132px;
            }
          }
        }
      }

      .map {
        &__list {
          margin: 60px 20px 0;

          &.mo-border-none {
            border-top: none;

            .map__list-head {
              padding-top: 0;
            }
          }

          &-head {
            display: block;
            padding-top: 62px;
          }

          .num {
            line-height: 2.13;
          }

        }

        &__accordion {
          position: static;
          width: 100%;
          min-height: auto;

          .img-box {
            position: static;
            width: 100%;
            min-height: 422px;
            margin-top: 34px;
          }

          &-item {
            &.is-active {
              padding-bottom: 60px;
            }
          }

          &-list {
            width: 100%;
          }
        }

        &__item {
          display: block;
          padding-top: 20px;
          border-top: 1px solid var(--border01);

          &-text {
            width: 100%;

            .tit {
              font-size: 18px;
              line-height: 30px;
              letter-spacing: -0.5px;
            }

            .txt {
              font-size: 14px;
              line-height: 26px;
              letter-spacing: -0.3px;
              color: #1d1d1f;
            }
          }

          &-image {
            width: 100%;
            margin-top: 34px;
          }
        }
      }

      .small-business {
        .service__img-wrap {
          display: block;
        }

        .service__text {
          max-width: none;
          margin: 60px 0 0;
        }

        &__card {
          margin: 60px 20px 0;
          padding: 30px 0 0;

          &-list {
            margin: 0;
          }

          &-item {
            width: 100%;
            margin: 60px 0 0;

            &:first-child {
              margin-top: 0;
            }
          }

          &-img {
            height: 223px;

            &::after {
              height: 154px;
            }
          }

          &-textbox {
            min-height: auto;
          }
        }
      }
    }
  }
}
