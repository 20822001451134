.#{$component-name} {
  &.service-main {
    .header.is-hide {
      transform: none;
    }

    .anchor-section {
      // transition: all .6s;
      background-color: #007dff;
    }

    .anchor-nav {
      .anchor-nav__menu {
        &.#{$hideClass} {
          opacity: 1;

          .menu__link {
            visibility: visible;
          }
        }

        .menu__wrap {
          padding: 153px 0;
        }
      }

      &.#{$fixedClass} {
        .menu__wrap {
          padding: 193px 0;
          top: -60px;
        }
      }
    }

    .track-animation {
      &__dimmed {
        opacity: 0.3;

        &.#{$showClass} {
          opacity: 0.7;
        }
      }

      &__main-area {
        width: 1120px;
      }

      &__highlight-area {
        max-width: 1110px;
        text-align: center;
      }
    }

    .container {
      padding-top: 0;
      padding-bottom: 0;
    }

    .full-screen {
      .cont__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }

    .full-video {
      overflow: hidden;
      position: relative;
      padding-top: 56.25%;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
      }

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
      }

      .cont {
        &__wrap {
          position: absolute;
          top: 50%;
          left: 50%;
          max-width: 1800px;
          width: 100%;
          height: 100%;
          padding: 0;
          z-index: 2;
          transform: translate(-50%, -50%);
        }
      }
    }

    //main
    .js-nav-section {
      &.full-screen {
        display: flex;
        overflow: hidden;
        position: relative;
        justify-content: space-between;
        min-height: 100vh;
        padding: 0;
        text-align: left;
        background: transparent;
      }

      &.content04 {
        .index-section__page-item:nth-child(2) {
          .icon-box {
            position: absolute;
            top: 50%;
            left: 0;
            width: 64px;
            height: 64px;
            padding: 8px;
            background-color: #fff;
            border-radius: 15px;
            transform: translateY(-50%);

            img {
              position: relative;
              width: 100%;
            }
          }
        }
      }
    }

    .index-section {
      width: 100%;

      &__inner {
        position: static;
      }

      &__title {
        font-size: 78px;
        line-height: 1.18;
        letter-spacing: -0.01px;
      }

      &__text-box {
        width: 574px;
        padding: 200px 20px 128px 0;
      }

      &__thumb-box {
        position: absolute;
        top: 0;
        left: 50%;
        height: 100vh;
        transform: translateX(240px);
        opacity: 0;
        transition: all 0.6s;

        .video-wrap {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
        }

        .img-wrap {
          overflow: hidden;
          position: relative;
          width: 100%;
          padding-top: 100%;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
        }

        &.#{$showClass} {
          opacity: 1;
        }

        &.#{$hideClass} {
          opacity: 0;
        }

        &.#{$fixedClass} {
          position: fixed;
          z-index: 10;
        }

        .img-wrap {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
        }

        .video-wrap {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
        }
      }

      &__sub-title {
        margin-top: 100px;
        font-weight: 700;

        em {
          font-weight: 800;
        }
      }

      &__page {
        margin-top: 99px;

        &-item {
          display: flex;
          position: relative;
          align-items: center;
          min-height: 105px;
          margin-top: 0;
          padding: 25px 5px 25px 114px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          &:first-child {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }

          img {
            position: absolute;
            top: 50%;
            left: 0;
            width: 64px;
            transform: translateY(-50%);
          }

          h4,
          p {
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }

          p {
            padding-right: 40px;
            margin-top: 1px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.6);
          }
        }

        &-link {
          &::before {
            width: 30px;
            height: 30px;
            margin-right: 0;
          }
        }

        &-text {
          width: 100%;
        }
      }

      &.theme-white {
        .index-section__page-item {
          border-bottom-color: rgba(255, 255, 255, 0.1);

          &:first-child {
            border-top-color: rgba(255, 255, 255, 0.1);
          }

          p {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }

    .content02,
    .content03 {
      .index-section {
        &.theme-white {
          .index-section__page-item {
            border-top-color: rgba(255, 255, 255, 0.1);

            &:first-child,
            &:nth-child(2n) {
              border-top-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }

    .content02 {
      .index-section {
        .video-wrap {
          background: url(/img/pc/service-main-1.png) no-repeat center / cover;
        }
      }
    }

    .content03 {
      .index-section {
        .video-wrap {
          background: url(/img/pc/service-main-2.png) no-repeat center / cover;
        }
      }
    }

    .content04 {
      .index-section {
        .video-wrap {
          background: url(/img/pc/service-main-3.png) no-repeat center / cover;
        }
      }
    }

    .content05 {
      .index-section {
        .video-wrap {
          background: url(/img/pc/service-main-4.png) no-repeat center / cover;
        }
      }
    }

    .content06 {
      .index-section {
        .video-wrap {
          background: url(/img/pc/service-main-5.png) no-repeat center / cover;
        }
      }
    }

    .content07 {
      .index-section {
        .video-wrap {
          background: url(/img/pc/service-main-6.png) no-repeat center / cover;
        }
      }
    }

    .content08 {
      .index-section {
        .video-wrap {
          background: url(/img/pc/service-main-7.png) no-repeat center / cover;
        }
      }
    }

    .content09 {
      .index-section {
        .video-wrap {
          background: url(/img/pc/service-main-9.png) no-repeat center / cover;
        }
      }
    }

    .content10 {
      .index-section {
        .video-wrap {
          background: url(/img/pc/service-main-8.png) no-repeat center / cover;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.service-main {
      .track-animation {
        &__main-area {
          width: 945px;
        }

        &__main-text {
          font-size: 80px;
        }

        &__highlight-text {
          padding-left: 96px;
        }

        &__highlight-area {
          max-width: 1135px;
        }
      }

      .index-section {
        &__thumb-box {
          transform: translateX(160px);
        }

        &__text-box {
          padding: 160px 20px 100px 0;
        }
      }

      .full-video {
        .cont {
          &__wrap {
            padding-left: 20.5%;
            padding-right: 13%;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.service-main {
      .header.is-hide {
        transform: translateY(-100%);
      }

      .btn-arrow__box {
        display: none;
      }

      .index-section__text-box {
        width: 49%;
      }

      .border-tb,
      .border-tb-sm {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      .anchor-nav {
        .anchor-nav__menu {
          &.#{$hideClass} {
            opacity: 0;

            .menu__link {
              visibility: hidden;
            }
          }

          .menu__wrap {
            padding: 0;
          }
        }

        &.#{$fixedClass} {
          .menu__wrap {
            padding: 0;
          }
        }
      }

      .track-animation {
        &__main-area {
          width: 805px;
        }

        &__main-text {
          font-size: 72px;
        }

        &__highlight-area {
          max-width: 957px;
        }

        &__highlight-text {
          padding-left: 6px;
        }
      }

      .full-video {
        padding-top: 1366px;

        .cont {
          &__wrap {
            padding-left: 11.03%;
            padding-right: 2.92%;
          }
        }
      }

      .index-section {
        &__title {
          font-size: 64px;
          line-height: 1.38;
        }

        &__text-box {
          width: 100%;
          max-height: none;
          padding: 0;
        }

        &__thumb-box {
          position: absolute;
          top: 140px;
          right: 30px;
          width: 472px;
          height: auto;
          padding: 0;
          opacity: 1;
          transform: none;

          &.#{$fixedClass} {
            position: absolute;
            z-index: 10;
          }

          &.#{$hideClass} {
            opacity: 1;
          }

          .img-wrap {
            position: static;
            transform: none;
          }

          .video-wrap {
            transform: none;
          }
        }

        &__sub-title {
          margin-top: 80px;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
          max-width: 420px;
        }

        &__page {
          margin-top: 273px;

          &-list {
            display: flex;
            flex-wrap: wrap;
          }

          &-item {
            width: calc(50% - 10px);
            padding: 15px 0 15px 88px;

            &:nth-child(2n) {
              margin-left: 20px;
              // border-top: 1px solid rgba(0, 0, 0, 0.1);
            }

            p {
              margin-top: 4px;
              line-height: 1.57;
            }
          }

          &-link {
            &::before {
              width: 24px;
              height: 24px;
              margin-right: 20px;
            }
          }
        }

        &.two-row-title {
          .index-section__page {
            margin-top: 185px;
          }
        }

        &.theme-white {
          .index-section__page-item {
            &:nth-child(2n) {
              border-top-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.service-main {
      .js-nav-section {
        &.content04 {
          .index-section__page-item:nth-child(2) {
            .icon-box {
              width: 54px;
              height: 54px;
              padding: 5px;
              border-radius: 11px;
            }
          }
        }
      }

      .track-animation {
        &__main-area {
          width: 610px;
        }

        &__highlight-area {
          max-width: 750px;
        }

        &__highlight-text {
          padding-left: 16px;
        }
      }

      .full-video {
        padding-top: 1024px;

        .cont {
          &__wrap {
            padding-left: 7.81%;
            padding-right: 3.9%;
          }

          &__title {
            font-size: 42px;
          }
        }
      }

      .index-section {
        &__title {
          font-size: 46px;
          line-height: 1.48;
        }

        &__thumb-box {
          top: 120px;
          width: 348px;
        }

        &__sub-title {
          margin-top: 60px;
          font-size: 18px;
          line-height: 1.56;
          letter-spacing: -0.5px;
          max-width: 320px;
        }

        &__page {
          margin-top: 176px;

          &-link {
            &::before {
              margin-top: -20px;
            }
          }

          &-item {
            width: calc(50% - 6px);
            min-height: 100px;
            padding: 15px 0 15px 70px;

            &:nth-child(2n) {
              margin-left: 12px;
            }

            img {
              width: 54px;
            }

            p {
              margin-top: 1px;
              font-size: 12px;
              line-height: 1.83;
              letter-spacing: -0.26px;
            }
          }
        }

        &.two-row-title {
          .index-section__page {
            margin-top: 108px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.service-main {
      .track-animation {
        &__main-area {
          width: auto;
        }

        &__main-text {
          padding: 0 30px;
          font-size: 38px;
        }

        &__highlight-text {
          padding: 0 30px;
        }
      }

      .full-video {
        padding-top: 812px;

        .cont {
          &__wrap {
            padding: 0 20px;
          }

          &__title {
            font-size: 36px;
          }
        }
      }

      .index-section {
        padding: 90px 20px 40px;

        &__sub-title {
          max-width: 100%;
        }

        &__thumb-box {
          position: static;
          width: 100%;
          height: auto;
          transform: none;

          &.#{$fixedClass} {
            position: static;
          }

          .img-wrap {
            position: relative;
            transform: none;
          }

          .video-wrap {
            position: relative;
            transform: none;
          }
        }

        &__page {
          margin-top: 60px;

          &-list {
            flex-wrap: nowrap;
            flex-direction: column;
          }

          &-item {
            width: auto;
            min-height: 90px;
            padding: 20px 0 20px 70px;

            &:nth-child(2n) {
              margin-left: 0;
              border-top: 0;
            }
          }

          &-link {
            &::before {
              margin-right: 0;
            }
          }
        }

        &.two-row-title {
          .index-section__page {
            margin-top: 60px;
          }
        }
      }

      .content02 {
        .index-section {
          .video-wrap {
            background: url(/img/mo/service-main-1.png) no-repeat center / cover;
          }
        }
      }

      .content03 {
        .index-section {
          .video-wrap {
            background: url(/img/mo/service-main-2.png) no-repeat center / cover;
          }
        }
      }

      .content04 {
        .index-section {
          .video-wrap {
            background: url(/img/mo/service-main-3.png) no-repeat center / cover;
          }
        }
      }

      .content05 {
        .index-section {
          .video-wrap {
            background: url(/img/mo/service-main-4.png) no-repeat center / cover;
          }
        }
      }

      .content06 {
        .index-section {
          .video-wrap {
            background: url(/img/mo/service-main-5.png) no-repeat center / cover;
          }
        }
      }

      .content07 {
        .index-section {
          .video-wrap {
            background: url(/img/mo/service-main-6.png) no-repeat center / cover;
          }
        }
      }

      .content08 {
        .index-section {
          .video-wrap {
            background: url(/img/mo/service-main-7.png) no-repeat center / cover;
          }
        }
      }

      .content09 {
        .index-section {
          .video-wrap {
            background: url(/img/mo/service-main-9.png) no-repeat center / cover;
          }
        }
      }

      .content10 {
        .index-section {
          .video-wrap {
            background: url(/img/mo/service-main-8.png) no-repeat center / cover;
          }
        }
      }
    }
  }
}
