$component-name: 'navercorp';

$screen-xsm: 375px;
$screen-sm: 767px;
$screen-xmd: 1023px;
$screen-md: 1599px;
$screen-lg: 1919px;
$screen-xlg: 1920px;

$color-default: #1d1d1f;
$color-black: #000;
$color-gray: #86868b;
$color-gray2: #6e6e73;
$color-gray3: #424245;
$color-gray4: #e4e6ea;
$color-white: #fff;
$color-point: #ff5f00;
$color-bg-blue: #f6f7fb;

$z-index-minus: -1;
$z-index-zero: 0;
$z-index-minimum: 1;
$z-index-header: 200;
$z-index-layer: 100;
$z-index-mo-nav: 1000;

$activeClass: 'is-active';
$openClass: 'is-open';
$selectedClass: 'is-selected';
$disabledClass: 'is-disabled';
$scrollClass: 'is-scroll';
$scrollUpClass: 'is-scroll-up';
$scrollLockClass: 'is-scroll-lock';
$fixedClass: 'is-fixed';
$stickyClass: 'is-sticky';
$showClass: 'is-show';
$hideClass: 'is-hide';
$downClass: 'is-down';
$posClass: 'is-position';
$bdClass: 'is-bold';
$ebClass: 'is-extrabold';

html {
  --blackWhite: #fff; //#000;
  --default: #1d1d1f; //#fff;
  --reverse: #fff; //#1d1d1f;
  --background01: #fff; //#060505;
  --background01Transparent: rgba(255,255,255,0.8); //000;
  --pageTitle: #1d1d1f; //#fff;
  --secTitle: #1d1d1f; //#fff;
  --transparent: #fff; //transparent
  --inputColor: #6e6e73; //#a1a1a6;
  --inputBox: #e4e6ea; //#2f2e30;
  --searchBox: #e4e6ea; //#2f2e30;
  --searchTxt: #a1a1a6; //#fff;
  --buttonType3: #1d1d1f; //#fff;
  --buttonType3Border: #e4e6ea; //#2f2e30;
  --buttonType3Background: #f1f1f4; //#4f4e52;
  --secDesc: #bbbbbe; //#6e6e73;
  --border01: #e4e6ea; //#2f2e30;
  --border02: #cfcfcf; //#424245;
  --border03: #e4e6ea; //#1d1d1f;
  --border04: #1d1d1f; // #6e6e73;
  --border05: #d8d8d8; // #2f2e30;
  --border06: #e4e6ea; // #6e6e73;
  --point: #fff; //#ff5f00;
  --buttonDownlodBefore: #000; //#424245;
  --listSub: #86868b; //#a1a1a6;
  --relateContent: #fbfbfd; //#060505;
  --buttonDownload: #1d1d1f; //#3f3d40;
  --accordionNum: #bbbbbe; //#6e6e73;
  --graphBar: #e0e0e0; //#424245;
  --graphTitle: #444; //#a1a1a6;
  --color-sub: #1d1d1f; //#a1a1a6;
  --color-sub02: #1d1d1f; //#424245;
  --color-sub03: #6e6e73; //#a1a1a6;
  --color-sub04: #bbbbbe; //#a1a1a6;
  --color-sub05: #444; //#fff;
  --outlinkBg: #e8e8e8; //#424245;
  --outlinkBg02: #1d1d1f; //#4f4e52;
  --outlinkBg03: #000; //#3f3d40;
  --outlinkBg04: #fff; //#424245;
  --paginationBg: #1d1d1f; //#2f2e30;
  --bgColor: #f1f1f4; //#1d1d1f;
  --bgColor02: #f6f7fb; //#1d1d1f;
  --bgColor03: #f6f7fb; // #2f2e30;
  --tab-cta: #6e6e73; //#424245;
  --tab-cta02: #bbbbbe; // #424245;
  --box-text: #424245; //#a1a1a6;
  --deleteBtn: #bbbbbe; //#4f4e52;
  --privacyBg: #f2f6ff; //#1d1d1f;
  --privacyTxt: #86868b; //#a1a1a6;
  --flowerBg: #ebf4ff; //#1d1d1f;
  --halfNum: #e4e6ea; //#424245;
  --flowerDesc: #a1a1a6; //#6e6e73;
  --newslistBg: #000; //#1d1d1f;
  --newsDate: #86868b; //#6e6e73;
  --neswBtn: #1d1d1f; //#2f2e30;
  --pagination: #f1f1fb; //#424245;
  --pagination-page-blind: #a1a1a6; //#424245;
  --tableTopBorder: #111; // #6e6e73;
  --tbodyBorder: #ddd; // #2f2e30;
  --naverDesc: #dddee1; // #383737;
  --numBg: #1d1d1f; // #6e6e73;
  --numColor: #ebecee; // #1d1d1f;
  --selectbox: #fff; // #2f2e30;
  --storyCategory: #f1f1f4; // #2f2e30;
  --storyCategory02: #f1f1f4; // #3f3d40;
  --storyCategorytit: #dddee1; // #424245;
  --storyDetailTxt: #424245; // #fff;
  --pagination-bullet: #cececf; // #2f2e30;
  --placeHoler: #b5b5b5; // #6e6e73;
  --blackTit: #111; // #fff;
  --black: #000; // #111;
  --detailColor: #2e2e2e; // #a1a1a6;
  --yearColor: #707070; // #a1a1a6;
  --scheduleColor: #424245; // #dddee1;
  --radioBorder: #e5e7eb; // #2f2e30;
  --mainBg: #f1f1f3; // #1d1d1f;
  --navRgba: rgba(29, 29, 31, 0.4); // rgba(255, 255, 255, 0.4);

  --btn-outlink: url(/img/common/btn-outlink.svg);
  --btn-outlink-sm: url(/img/common/btn-outlink-sm.svg);
  --ic-outlink02: url(/img/common/ic-outlink-black2.png);
  --ic-outlink03: url(/img/common/ic-outlink-black.png);
  --ic-accordion: url(/img/common/ic-accordion.png);
  --ic-accordion-close: url(/img/common/ic-accordion-close.png);
  --btn-circle-arrow-left: url(/img/common/btn-circle-arrow-left.png);
  --ic-back: url(/img/common/ic-back.png);
  --checkbox: url(../img/common/ic-checkbox.png);
  --esg-privacy-icon01: url(/img/pc/esg-privacy-icon01.png);
  --esg-privacy-icon02: url(/img/pc/esg-privacy-icon02.png);
  --esg-privacy-icon03: url(/img/pc/esg-privacy-icon03.png);
  --ic-search: url(/img/common/ic-search.png);
  --ic-pagination: url(/img/common/ic-pagination-arrow.png);
  --ic-pagination02: url(/img/common/ic-pagination-arrow-black.png);
  --ic-pagination03: url(/img/common/ic-pagination-arrow2.png);
  --ic-pagination-gray: url(/img/common/ic-pagination-arrow-gray.png);
  --ic-pagination-gray-hover: url(/img/common/ic-pagination-arrow-gray-hover.png);
  --ic-dropdown: url(/img/common/ic-dropdown.svg);
  --ic-download: url(/img/common/ic-download-black.png);
  --ic-download-w: url(/img/common/ic-download-white.png);
  --naver-contact-icon01: url(/img/pc/naver-contact-icon01.png);
  --naver-contact-icon02: url(/img/pc/naver-contact-icon02.png);
  --naver-contact-icon03: url(/img/pc/naver-contact-icon03.png);
  --ic-circle-close: url(/img/common/ic-circle-close.png);
  --ic-quicklink: url(/img/common/ic-quicklink-black.png);
  --ic-list: url(/img/common/ic-list.png);
  --ic-media-download: url(/img/common/ic-media-download-black.png);
  --ic-media-download02: url(/img/common/ic-media-download.png);
  --ic-search-tab: url(/img/common/ic-search-tab.svg);
  --ic-arrow-down: url(/img/common/ic-arrow-down-black.png);
  --ic-arrow-down-hover: url(/img/common/ic-arrow-down-hover.png);
  --ic-title-arrow: url(/img/common/ic-title-arrow.png);
  --ic-arrow-primary: url(/img/common/ic-arrow-primary.png);
  --ic-outlink-circle: url(/img/common/ic-outlink-circle.png);
  --logo: url(/img/common/logo.svg);
  --ic-nav-close: url(/img/common/ic-nav-close.svg);
  --ic-outlink-nav: url(/img/common/ic-outlink-black3.svg);
  --ic-lang-dropdown: url(/img/common/ic-lang-dropdown.png);
  --ic-cate-dropdown: url(/img/common/ic-cate-dropdown.png);
  --ic-search-delete: url(/img/common/ic-search-delete.png);
  --btn-share-link: url(/img/common/btn-share-link.svg);
}

// 다크모드
html.theme-dark {
  --blackWhite: #000;
  --default: #fff;
  --reverse: #1d1d1f;
  --background01: #1d1d1f;
  --background01Transparent: rgba(0,0,0,0.8);
  --pageTitle: #fff;
  --secTitle: #fff;
  --transparent: transparent;
  --inputColor: #a1a1a6;
  --inputBox: #2f2e30;
  --searchBox: #2f2e30;
  --searchTxt: #fff;
  --buttonType3: #fff;
  --buttonType3Border: #2f2e30;
  --buttonType3Background: #4f4e52;
  --secDesc: #6e6e73;
  --border01: #2f2e30;
  --border02: #424245;
  --border03: #1d1d1f;
  --border04: #6e6e73;
  --border05: #2f2e30;
  --border06: #6e6e73;
  --point: #ff5f00;
  --buttonDownlodBefore: #424245;
  --listSub: #a1a1a6;
  --relateContent: #060505;
  --buttonDownload: #3f3d40;
  --accordionNum: #6e6e73;
  --graphBar: #424245;
  --graphTitle: #a1a1a6;
  --color-sub: #a1a1a6;
  --color-sub02: #424245;
  --color-sub03: #a1a1a6;
  --color-sub04: #a1a1a6;
  --color-sub05: #fff;
  --outlinkBg: #424245;
  --outlinkBg02: #4f4e52;
  --outlinkBg03: #3f3d40;
  --outlinkBg04: #424245;
  --paginationBg: #2f2e30;
  --bgColor: #1d1d1f;
  --bgColor02: #1d1d1f;
  --bgColor03: #2f2e30;
  --tab-cta: #424245;
  --tab-cta02: #424245;
  --box-text: #a1a1a6;
  --deleteBtn: #4f4e52;
  --privacyBg: #1d1d1f;
  --privacyTxt: #a1a1a6;
  --flowerBg: #1d1d1f;
  --halfNum: #424245;
  --flowerDesc: #6e6e73;
  --newslistBg: #1d1d1f;
  --newsDate: #6e6e73;
  --neswBtn: #2f2e30;
  --pagination: #424245;
  --pagination-page-blind: #424245;
  --tableTopBorder: #6e6e73;
  --tbodyBorder: #2f2e30;
  --naverDesc: #383737;
  --numBg: #6e6e73;
  --numColor: #1d1d1f;
  --selectbox: #2f2e30;
  --storyCategory: #2f2e30;
  --storyCategory02: #3f3d40;
  --storyCategorytit: #424245;
  --storyDetailTxt: #fff;
  --pagination-bullet: #2f2e30;
  --placeHoler: #6e6e73;
  --blackTit: #fff;
  --black: #111;
  --detailColor: #a1a1a6;
  --yearColor: #a1a1a6;
  --scheduleColor: #dddee1;
  --radioBorder: #2f2e30;
  --mainBg: #1d1d1f;
  --navRgba: rgba(255, 255, 255, 0.4);

  --btn-outlink: url(/img/common/btn-outlink-white.png);
  --btn-outlink-sm: url(/img/common/btn-outlink-sm-white.svg);
  --ic-outlink02: url(/img/common/ic-outlink-white2.png);
  --ic-outlink03: url(/img/common/ic-outlink-white.png);
  --ic-accordion: url(/img/common/ic-accordion-white.png);
  --ic-accordion-close: url(/img/common/ic-accordion-close-white.png);
  --btn-circle-arrow-left: url(/img/common/btn-circle-arrow-left-dark.png);
  --ic-back: url(/img/common/ic-back-white.png);
  --checkbox: url(../img/common/ic-check-black.png);
  --esg-privacy-icon01: url(/img/pc/esg-privacy-icon01-white.png);
  --esg-privacy-icon02: url(/img/pc/esg-privacy-icon02-white.png);
  --esg-privacy-icon03: url(/img/pc/esg-privacy-icon03-white.png);
  --ic-search: url(/img/common/ic-search-w.png);
  --ic-pagination: url(/img/common/ic-pagination-arrow-black.png);
  --ic-pagination02: url(/img/common/ic-pagination-arrow-black-hover.png);
  --ic-pagination03: url(/img/common/ic-pagination-arrow2-dark.png);
  --ic-dropdown: url(/img/common/ic-dropdown-w.svg);
  --ic-download: url(/img/common/ic-download-white.png);
  --ic-download-w: url(/img/common/ic-download-black.png);
  --naver-contact-icon01: url(/img/pc/naver-contact-icon01-white.png);
  --naver-contact-icon02: url(/img/pc/naver-contact-icon02-white.png);
  --naver-contact-icon03: url(/img/pc/naver-contact-icon03-white.png);
  --ic-circle-close: url(/img/common/ic-circle-close-b.png);
  --ic-quicklink: url(/img/common/ic-quicklink.png);
  --ic-list: url(/img/common/ic-list-white.png);
  --ic-media-download: url(/img/common/ic-download-underbar.png);
  --ic-media-download02: url(/img/common/ic-media-download-dark.png);
  --ic-search-tab: url(/img/common/ic-search-w.svg);
  --ic-arrow-down: url(/img/common/ic-arrow-down.png);
  --ic-title-arrow: url(/img/common/ic-title-arrow-white.png);
  --ic-arrow-primary: url(/img/common/ic-arrow-primary-w.png);
  --ic-outlink-circle: url(/img/common/ic-outlink-gray2.png);
  --logo: url(/img/common/logo-w.svg);
  --ic-nav-close: url(/img/common/ic-nav-close-w.svg);
  --ic-outlink-nav: url(/img/common/ic-outlink-white4.svg);
  --ic-lang-dropdown: url(/img/common/ic-lang-dropdown-w.png);
  --ic-cate-dropdown: url(/img/common/ic-cate-dropdown-w.png);
  --ic-search-delete: url(/img/common/ic-search-delete-b.png);
  --btn-share-link: url(/img/common/btn-share-link-w.svg);
}
